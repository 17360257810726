import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";

export const DialogConfirmRegularCubUnstake = <T,>({
  data,
  onConfirm,
  onCancel,
}: {
  data: T;
  onConfirm: (data: T) => void;
  onCancel: Function;
}) => {
  return (
    <>
      <Dialog
        open={!!data}
        onClose={() => onCancel()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Unstaking
          <IconButton
            aria-label="close"
            onClick={() => onCancel()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="mb-3">
              Unstaking your Lion Cub has a 10% risk of it being stolen by an
              Evil Cub.
            </div>
            <div>Are you sure you want to unstake?</div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => onCancel()}
            autoFocus
            color="error"
            variant="contained"
          >
            No
          </Button>
          <Button
            onClick={() => onConfirm(data)}
            color="success"
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
