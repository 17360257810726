import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import {TOKEN_NAME} from "../config";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      backgroundColor: "transparent !important",
    },
  },
  cardRoot: {
    backgroundColor: "transparent",
    color: "#FFF",
  },
  gridItem: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  },
  listItemRoot: {
    color: "#9FB5DE",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#252435",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#2E2C42",
  },
  td: {
    color: "#E2E2ED",
  },
  th: {
    backgroundColor: "#2E2C42",
    color: "#FFF",
    textTransform: "uppercase",
    textAlign: "center",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(action: any, notes: any, risk: any) {
  return { action, notes, risk };
}

const rows = [
  createData(
    <Typography>
      Mint a newborn lion using <span className="pink">{TOKEN_NAME}</span>
    </Typography>,
    <Typography>
      There is a <span className="pink">10%</span> chance that the newborn turns
      into an evil cub."
    </Typography>,
    "No risk"
  ),
  createData(
    <Typography>
      Feed a newborn lion <span className="pink">{TOKEN_NAME}</span>
    </Typography>,
    <Typography>
      Risky Game <span className="pink">*</span>
    </Typography>,
    "If a newborn isn’t fed, it will die and get burnt."
  ),
];

const Tokenomics = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:900px)");

  if (isMobile) {
    return (
      <Grid item>
        <Box id="tokenomics">
          <Container>
            <a
              style={{
                color: "#fff",
                padding: "8px 12px",
                textAlign: "center",
                display: "block",
                border: "1px solid #5C4CB6",
                borderRadius: "4px",
                marginBottom: "20px",
              }}
              href={require("../assets/fuji-tokenomics.png").default}
            >
              View tokenomics
            </a>
          </Container>
        </Box>
      </Grid>
    );
  }

  return (
    <Grid item justifyContent="center" sx={{ marginBottom: "5em" }}>
      <Container>
        <Box id="tokenomics" className="row">
          <div className="fuji-border col-4 mx-auto mt-5">&nbsp;</div>
        </Box>
        <div>
          <Grid container direction="column" style={{ textAlign: "center" }}>
            <Box sx={{ marginTop: 5, marginBottom: 5 }}>
              <Typography variant="h2" gutterBottom>
                Tokenomics
              </Typography>
            </Box>
            <Paper
              sx={{
                color: "#BCCEEF",
                backgroundColor: "transparent",
                padding: 5,
                textAlign: "left",
                border: "1px solid #5C4CB6",
              }}
              elevation={5}
            >
              <Box sx={{ flexGrow: 1, marginBottom: "2em" }}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                >
                  <Grid
                    item
                    md={1}
                    sx={{ marginRight: 3 }}
                    className={classes.gridItem}
                  >
                    <img
                      alt=""
                      height="130px"
                      width="130px"
                      src={require("../layout/images/fuji-token.png").default}
                    />
                  </Grid>
                  <Grid item md={1} sx={{ padding: 0 }}>
                    <Typography variant="h1" component="h1">
                      FUJI
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Typography variant="body2">
                <span className="pink">{TOKEN_NAME}</span> is the most precious asset.
                Hold tightly to your <span className="pink">{TOKEN_NAME}</span>. It
                will have many use cases for years to come. Maximum{" "}
                <span className="pink">{TOKEN_NAME}</span> supply is{" "}
                <span className="pink">15,000,000 {TOKEN_NAME}</span>.
              </Typography>

              <Box className="row">
                <List sx={{ width: "100%" }}>
                  <ListItem alignItems="flex-start">
                    <ListItemIcon style={{ color: "#839AC6" }}>
                      <KeyboardArrowRightIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <span className="pink">10,000,000</span> allocated for
                      lion/cub staking faucet
                    </ListItemText>
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemIcon style={{ color: "#839AC6" }}>
                      <KeyboardArrowRightIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <span className="pink">2,000,000</span> locked for 12 months (future development)
                    </ListItemText>
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemIcon style={{ color: "#839AC6" }}>
                      <KeyboardArrowRightIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <span className="pink">2,000,000</span> allocated for "alpha game"
                    </ListItemText>
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemIcon style={{ color: "#839AC6" }}>
                      <KeyboardArrowRightIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <span className="pink">500,000</span> allocated for the
                      development team, fully locked.
                      <span className="pink">50%</span> unlocked after{" "}
                      <span className="pink">138 days</span> and remainder
                      unlocking linearly over the following{" "}
                      <span className="pink">138 days</span>. Team retains
                      option to lock-up allocation for additional time
                    </ListItemText>
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemIcon style={{ color: "#839AC6" }}>
                      <KeyboardArrowRightIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <span className="pink">500,000</span> allocated for
                      community activities. This includes liquidity, airdrops
                      and giveaways
                    </ListItemText>
                  </ListItem>
                </List>
              </Box>

              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={11}>
                  <TableContainer
                    sx={{
                      marginTop: "1em",
                    }}
                    component={Paper}
                  >
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <StyledTableRow>
                          <TableCell component="th">Action</TableCell>
                          <TableCell component="th">Notes</TableCell>
                          <TableCell component="th">Risk</TableCell>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <StyledTableRow key={row.action}>
                            <TableCell scope="row">{row.action}</TableCell>
                            <TableCell align="left">{row.notes}</TableCell>
                            <TableCell align="left">{row.risk}</TableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>

              <Box
                sx={{ marginTop: "40px", marginBottom: 3, color: "#E2E2ED" }}
              >
                <Typography variant="h3" gutterBottom>
                  Risky Game
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2">
                  You can choose to play{" "}
                  <span className="pink">"Risky Game"</span> with your newborn
                  lion.
                </Typography>
              </Box>
              <br />

              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={11}>
                  <TableContainer
                    sx={{
                      marginBottom: "5em",
                    }}
                    component={Paper}
                  >
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <StyledTableRow>
                          <TableCell component="th" align="center">
                            No Risk
                          </TableCell>
                          <TableCell component="th" align="center">
                            Risk
                          </TableCell>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        <StyledTableRow key={"no-risk"}>
                          <TableCell scope="row">
                            When minting a newborn lion, you feed it{" "}
                            <span className="pink">10 {TOKEN_NAME}</span> every single
                            day and it takes 5 days to grow into a lion cub
                          </TableCell>
                          <TableCell align="left">
                            <Box>
                              When minting a newborn lion, you feed it all the{" "}
                              <span className="pink">{TOKEN_NAME} (50)</span> it needs
                              upfront.
                            </Box>
                            <br />
                            <Box>It will grow into a lion cub immediately</Box>
                            <br />
                            <Box>
                              However, there is a{" "}
                              <span className="pink">10%</span> chance that by
                              doing this, the cub gets stolen by the evil lion
                              pool and distributed to a random evil cub.
                            </Box>
                          </TableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>

              <Box sx={{ flexGrow: 1, marginBottom: "2em" }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  columns={12}
                  spacing={2}
                  sx={{ flexGrow: 1 }}
                >
                  <Grid item xs={3}>
                    <img
                      alt=""
                      height="100%"
                      width="100%"
                      src={require("../layout/images/lion-gen0.png").default}
                    />
                  </Grid>

                  <Grid item xs={9}>
                    <Grid item xs={4}>
                      <div className="fuji-border ">&nbsp;</div>
                    </Grid>
                    <br />
                    <Typography variant="h2" className="pink">
                      Gen 0
                    </Typography>
                    <br />
                    <Typography variant="body2">
                      These are the rulers of the Fuji Ecosystem. They are the
                      only ones able to breed and will have the most value in
                      the future. There will only ever be{" "}
                      <span className="pink">1,111</span> Gen 0 Lions.
                    </Typography>
                  </Grid>

                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={2}>
                      <Box sx={{ marginTop: 5, color: "#E2E2ED" }}>
                        <Typography variant="h2" gutterBottom>
                          Minting
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={11}>
                      <TableContainer
                        sx={{
                          marginTop: "1em",
                          marginBottom: "2.5em",
                        }}
                        component={Paper}
                      >
                        <Table
                          sx={{ minWidth: 700 }}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <StyledTableRow>
                              <TableCell component="th" align="center">
                                Token ID
                              </TableCell>
                              <TableCell component="th" align="center">
                                Price
                              </TableCell>
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            <StyledTableRow>
                              <TableCell align="center" scope="row">
                                <span className="pink">1,111</span> (Gen 0
                                Lions)
                              </TableCell>
                              <TableCell align="center">
                                <span className="pink">2.2 SOL</span>
                              </TableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>

                    <Grid item xs={11}>
                      <TableContainer
                        sx={{
                          marginBottom: "3em",
                        }}
                        component={Paper}
                      >
                        <Table
                          sx={{ minWidth: 700 }}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <StyledTableRow>
                              <TableCell component="th" align="center">
                                Action
                              </TableCell>
                              <TableCell component="th" align="center">
                                Notes
                              </TableCell>
                              <TableCell component="th" align="center">
                                Risk
                              </TableCell>
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            <StyledTableRow>
                              <TableCell align="center" scope="row">
                                Staking
                              </TableCell>
                              <TableCell align="left">
                                Receive <span className="pink">{TOKEN_NAME}</span>
                              </TableCell>
                              <TableCell align="center">No risk</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <TableCell align="center" scope="row">
                                Breed
                              </TableCell>
                              <TableCell align="left">
                                <Box>
                                  Cost <span className="pink">150 {TOKEN_NAME}</span>.
                                  Increase by 150
                                </Box>
                                <Box>every generation</Box>
                              </TableCell>
                              <TableCell align="center">No risk</TableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>

                    <Grid item xs={4}>
                      <Box sx={{ color: "#E2E2ED" }}>
                        <Typography variant="h2" gutterBottom>
                          Gen 1 and beyond
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={11}>
                      <TableContainer
                        sx={{
                          marginTop: "1em",
                          marginBottom: "5em",
                        }}
                        component={Paper}
                      >
                        <Table
                          sx={{ minWidth: 700 }}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <StyledTableRow>
                              <TableCell component="th" align="center">
                                Token ID
                              </TableCell>
                              <TableCell component="th" align="center">
                                Price
                              </TableCell>
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            <StyledTableRow>
                              <TableCell align="center" scope="row">
                                <span className="pink">1-2500</span> (Gen 1
                                Lions)
                              </TableCell>
                              <TableCell align="center">
                                <span className="pink">150 {TOKEN_NAME}</span>
                              </TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <TableCell align="center" scope="row">
                                <span className="pink">2501-5000</span> (Gen 2
                                Lions)
                              </TableCell>
                              <TableCell align="center">
                                <span className="pink">300 {TOKEN_NAME}</span>
                              </TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <TableCell align="center" scope="row">
                                <span className="pink">5001-7500</span> (Gen 3
                                Lions)
                              </TableCell>
                              <TableCell align="center">
                                <span className="pink">450 {TOKEN_NAME}</span>
                              </TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <TableCell align="center" scope="row">
                                <span className="pink">7501-10000</span> (Gen 4
                                Lions)
                              </TableCell>
                              <TableCell align="center">
                                <span className="pink">600 {TOKEN_NAME}</span>
                              </TableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>

                  <Grid item xs={3}>
                    <img
                      alt=""
                      height="100%"
                      width="100%"
                      src={require("../layout/images/lion-cub.png").default}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Grid item xs={4}>
                      <div className="fuji-border ">&nbsp;</div>
                    </Grid>
                    <br />
                    <Typography variant="h2" className="pink">
                      Lion Cubs
                    </Typography>
                    <br />
                    <Typography variant="body2">
                      You have a <span className="pink">90%</span> chance of
                      minting a Lion cub, each with unique traits.
                    </Typography>
                  </Grid>

                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={11}>
                      <TableContainer
                        sx={{
                          marginTop: "2em",
                          marginBottom: "5em",
                        }}
                        component={Paper}
                      >
                        <Table
                          sx={{ minWidth: 700 }}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <StyledTableRow>
                              <TableCell component="th" align="center">
                                Action
                              </TableCell>
                              <TableCell component="th" align="center">
                                Notes
                              </TableCell>
                              <TableCell component="th" align="center">
                                Risk
                              </TableCell>
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            <StyledTableRow>
                              <TableCell align="center" scope="row">
                                Enter Fuji Forest{" "}
                                <span className="pink">(Stake)</span>
                              </TableCell>
                              <TableCell align="center">
                                Accumulate <span className="pink">1 {TOKEN_NAME}</span>
                                /day
                              </TableCell>
                              <TableCell align="center">No risk</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <TableCell align="center" scope="row">
                                Claim <span className="pink">{TOKEN_NAME}</span>
                              </TableCell>
                              <TableCell align="left">
                                Receive <span className="pink">80%</span> of{" "}
                                <span className="pink">{TOKEN_NAME}</span> accumulated
                                on cub
                              </TableCell>
                              <TableCell align="left">
                                <Box>
                                  Evil cubs take a guaranteed{" "}
                                  <span className="pink">20%</span> tax on
                                  claimed <span className="pink">{TOKEN_NAME}</span> in
                                  return for not destroying the forest.
                                </Box>
                                <br />
                                <Box>
                                  Taxed <span className="pink">{TOKEN_NAME}</span> is
                                  split among all staked evil cubs equally
                                </Box>
                              </TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <TableCell align="center" scope="row">
                                Leave Forest{" "}
                                <span className="pink">(Unstake)</span>
                              </TableCell>
                              <TableCell align="left">
                                Cub is removed from Forest
                              </TableCell>
                              <TableCell align="left">
                                <span className="pink">10%</span> chance of lion
                                cub getting stolen by evil cubs. Lion cub will
                                be randomly distributed to a staked evil cub.
                              </TableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>

                  <Grid item xs={3}>
                    <img
                      alt=""
                      height="100%"
                      width="100%"
                      src={
                        require("../layout/images/lion-cub-evil.png").default
                      }
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Grid item xs={4}>
                      <div className="fuji-border ">&nbsp;</div>
                    </Grid>
                    <br />
                    <Typography variant="h2" className="pink">
                      Evil Lion Cubs
                    </Typography>
                    <br />
                    <Typography variant="body2">
                      You have a <span className="pink">10%</span> chance of
                      minting an evil lion cub, each with unique traits.
                    </Typography>
                    <Typography variant="body2">
                      Only staked evil cubs are able to steal lion cubs or earn
                      the <span className="pink">{TOKEN_NAME}</span> tax.
                    </Typography>
                  </Grid>
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={11}>
                      <TableContainer
                        sx={{
                          marginTop: "2em",
                          marginBottom: "5em",
                        }}
                        component={Paper}
                      >
                        <Table
                          sx={{ minWidth: 700 }}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <StyledTableRow>
                              <TableCell component="th" align="center">
                                Action
                              </TableCell>
                              <TableCell component="th" align="center">
                                Notes
                              </TableCell>
                              <TableCell component="th" align="center">
                                Risk
                              </TableCell>
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            <StyledTableRow>
                              <TableCell align="center" scope="row">
                                Enter Fuji Forest{" "}
                                <span className="pink">(Stake)</span>
                              </TableCell>
                              <TableCell align="left">
                                Earn share of <span className="pink">20%</span>{" "}
                                tax of all <span className="pink">{TOKEN_NAME}</span>{" "}
                                <br />
                                generated by lion cubs in forest
                              </TableCell>
                              <TableCell align="center">No risk</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <TableCell align="center" scope="row">
                                Claim <span className="pink">{TOKEN_NAME}</span>
                              </TableCell>
                              <TableCell align="left">
                                Receive all {TOKEN_NAME} taxes accrued for a <br />
                                staked lion
                              </TableCell>
                              <TableCell align="center">No risk</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <TableCell align="center" scope="row">
                                Leave Forest{" "}
                                <span className="pink">(Unstake)</span>
                              </TableCell>
                              <TableCell align="left">
                                Cub is removed from Forest
                              </TableCell>
                              <TableCell align="center">No risk</TableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </div>
      </Container>
    </Grid>
  );
};

export default Tokenomics;
