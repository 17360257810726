import * as React from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@material-ui/core/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { TOKEN_NAME } from "../config";

const WhitePaper = () => {
  return (
    <Grid className="whitepaper" item>
      <Container>
        <Box id="whitepaper" className="row">
          <div className="fuji-border col-4 mx-auto mt-5">&nbsp;</div>
        </Box>
        <div>
          <div className="flex items-center flex-col">
            <Box sx={{ marginTop: 5, marginBottom: 5 }}>
              <Typography variant="h2" gutterBottom>
                Whitepaper
              </Typography>
            </Box>
            <Paper
              sx={{
                color: "#BCCEEF",
                backgroundColor: "#201E2F",
                padding: 5,
                textAlign: "left",
                border: "1px solid #5C4CB6",
              }}
              elevation={5}
            >
              <Typography variant="body2">
                In a mystical forest lying in the great Fuji Mountains, a
                mysterious pride of lions hunt for a special token known as{" "}
                <span className="pink">{TOKEN_NAME}</span> that this forest
                revolves around. These Fuji Lions need the precious{" "}
                <span className="pink">{TOKEN_NAME}</span> for many things, but
                the most important is to birth and feed new lions. Most cubs
                turn out to be quite normal and hunt for{" "}
                <span className="pink">{TOKEN_NAME}</span> alongside their
                parents, yet sometimes the wrong combination of{" "}
                <span className="pink">{TOKEN_NAME}</span> and lion yields
                something even the most courageous lions fear.
              </Typography>
              <br />
              <Typography variant="body2">
                Sometimes, these newborn lions have the tiniest bit of darkness
                in their hearts and they grow up to be a ruthless evil lion cub.
                The evil lion cubs’ sole purpose is to steal all the{" "}
                <span className="pink">{TOKEN_NAME}</span> they can get their
                hands on as well as any regular lion cub in hopes of converting
                them to the dark side.
              </Typography>
              <br />
              <Typography variant="body2">
                In an attempt to protect their precious lion cubs, the lions
                compromised with the evil lion cubs: they will give{" "}
                <span className="pink">20%</span> of all the{" "}
                <span className="pink">{TOKEN_NAME}</span> produced by the
                regular lion cubs to the evil lions as long as they don’t try to
                steal the lion cubs that are safe in the Fuji Forest.
              </Typography>
              <br />
              <Typography variant="body2">
                Yet, there’s always a few lion cubs that want to venture out of
                the Fuji Forest and explore the rest of the world. For this, the
                evil lions have free reign.
              </Typography>

              <Box sx={{ marginTop: 5, color: "#E2E2ED" }}>
                <Typography variant="h2" gutterBottom>
                  TL; DR
                </Typography>
              </Box>
              <Box className="row">
                <List sx={{ width: "100%" }}>
                  <ListItem alignItems="flex-start">
                    <ListItemIcon style={{ color: "#839AC6" }}>
                      <KeyboardArrowRightIcon />
                    </ListItemIcon>
                    <ListItemText>
                      Only ever will be <span className="pink">1,111</span> Gen
                      0 Fuji Lions, minted for{" "}
                      <span className="pink">2.2 SOL</span> each. The{" "}
                      <span className="pink">10,000</span> new-born lions are
                      minted by hunting for{" "}
                      <span className="pink">{TOKEN_NAME}</span>. Each Gen 0
                      Lion produces{" "}
                      <span className="pink">10 {TOKEN_NAME}</span> a day.
                    </ListItemText>
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemIcon style={{ color: "#839AC6" }}>
                      <KeyboardArrowRightIcon />
                    </ListItemIcon>
                    <ListItemText>
                      Newborn lions must be fed{" "}
                      <span className="pink">50 {TOKEN_NAME}</span> either all
                      at once or <span className="pink">10 {TOKEN_NAME}</span>{" "}
                      every day to grow into a lion cub or else the newborn lion
                      will die and be burnt. After 5 days of feeding the newborn
                      lion, the newborn lion will grow into a Gen 1 Lion(cub)
                      There is a risky game that can be played here (please
                      reference risky game section below)
                    </ListItemText>
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemIcon style={{ color: "#839AC6" }}>
                      <KeyboardArrowRightIcon />
                    </ListItemIcon>
                    <ListItemText>
                      Cubs can be staked in the Fuji Forest to earn{" "}
                      <span className="pink">{TOKEN_NAME}</span>, but must pay a
                      tax anytime they claim their{" "}
                      <span className="pink">{TOKEN_NAME}</span>. This{" "}
                      <span className="pink">{TOKEN_NAME}</span> will be
                      dispersed among the staked evil cubs equally.
                    </ListItemText>
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemIcon style={{ color: "#839AC6" }}>
                      <KeyboardArrowRightIcon />
                    </ListItemIcon>
                    <ListItemText>
                      If a regular lion cub is unstaked from the Fuji Forest,
                      the evil lion cubs will try to steal the regular cub. If
                      the evil cubs are successful, the regular lion cub will go
                      to a random evil cub holder that is staking their cub.
                    </ListItemText>
                  </ListItem>
                </List>
              </Box>
            </Paper>
          </div>
        </div>
      </Container>
    </Grid>
  );
};

export default WhitePaper;
