import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import {useMediaQuery} from "@mui/material";
import {TOKEN_NAME} from "../config";


const useStyles = makeStyles(theme => ({
  listItem: {
    fontSize: ".9em",
    color: "#BCCEEF",
    marginTop: "9px !important",
    marginLeft: "-25px !important",
    paddingTop: "0 !important",
    paddingLeft: "0 !important",
  }
}));

const Roadmap = () => {
  const isMobile = useMediaQuery('(max-width:900px)');
  const classes = useStyles();

  return (
    <Grid container
      className="bg-section"
    >
      <Container>
        <Grid container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          columns={12}
          spacing={2}
        >
          <Grid sm={6} item>
            {/* left side */}

            {/* timeline item 1 */}
            <Grid container
              direction="row"
              justifyContent="flex-start"
              spacing={2}
            >

              <Grid item xs={1}>
                {/* timeline item 1 left dot */}
                <div className="row">
                  <div className="col">&nbsp;</div>
                  <div className="col">&nbsp;</div>
                </div>
                <h5 className="m-2">
                  <span className="badge badge-pill ">&nbsp;</span>
                </h5>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    marginLeft: '10px',
                  }}
                  className="h-50"
                >
                  <Box className="border-left">&nbsp;</Box>
                  <Box className="border-left">&nbsp;</Box>
                </Box>
              </Grid>

              <Grid item xs={10}>
                {/* timeline item 1 event content */}
                <div className="card-body">
                  <h4 id="roadmap" className="card-title">
                    <span>Phase 1</span>Pre-mint
                  </h4>
                  <List>
                    <ListItem alignItems="flex-start">
                      <ListItemIcon sx={{ color: "#839AC6" }}>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText className={classes.listItem}
                        secondary={
                         <React.Fragment>
                           <Typography
                             component="span"
                             variant="body2">Start Twitter</Typography>
                         </React.Fragment>
                       }/>
                     </ListItem>
                     <ListItem alignItems="flex-start">
                       <ListItemIcon style={{ color: "#839AC6" }}>
                         <KeyboardArrowRightIcon />
                       </ListItemIcon>
                       <ListItemText className={classes.listItem}
                        secondary={
                          <React.Fragment>
                           <Typography
                             component="span"
                             variant="body2">100% WL mint</Typography>
                         </React.Fragment>
                       }/>
                      </ListItem>
                  </List>
                </div>
              </Grid>

            {/*/row*/}
            </Grid>

            {/* timeline item 2 */}
            <Grid container
              direction="row"
              justifyContent="flex-start"
              spacing={2}
            >
              <Grid item xs={1}>
                <div className="row">
                  <div className="col">&nbsp;</div>
                  <div className="col">&nbsp;</div>
                </div>
                <h5 className="m-2">
                  <span className="badge badge-pill">&nbsp;</span>
                </h5>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    marginLeft: '10px',
                  }}
                  className="h-50"
                >
                  <div className="col border-left">&nbsp;</div>
                  <div className="col border-left">&nbsp;</div>
                </Box>
              </Grid>

              <Grid item xs={10}>
                <div className="card-body">
                  <h4 className="card-title">
                    <span>Phase 2</span>Mint
                  </h4>
                  <List>
                    <ListItem alignItems="flex-start">
                      <ListItemIcon sx={{ color: "#839AC6" }}>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText className={classes.listItem}
                        secondary={
                         <React.Fragment>
                           <Typography
                             component="span"
                             variant="body2">12 hour mint period for WL addresses</Typography>
                         </React.Fragment>
                       }/>
                     </ListItem>
                  </List>
                </div>
              </Grid>

            </Grid>
            {/*/row*/}

            {/* timeline item 3 */}
            <Grid container
              direction="row"
              justifyContent="flex-start"
              spacing={2}
            >
              <Grid item xs={1}>
                <div className="row">
                  <div className="col">&nbsp;</div>
                  <div className="col">&nbsp;</div>
                </div>
                <h5 className="m-2">
                  <span className="badge badge-pill ">&nbsp;</span>
                </h5>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    marginLeft: '10px',
                  }}
                  className="h-50"
                >
                  <div className="col border-left">&nbsp;</div>
                  <div className="col border-left">&nbsp;</div>
                </Box>
              </Grid>

              <Grid item xs={10}>
                <div className="card-body">
                  <h4 className="card-title">
                    <span>Phase 3</span>Community
                  </h4>
                  <List>
                    <ListItem alignItems="flex-start">
                      <ListItemIcon sx={{ color: "#839AC6" }}>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText className={classes.listItem}
                        secondary={
                         <React.Fragment>
                           <Typography
                             component="span"
                             variant="body2">Private discord channel (Fuji Forest) for Lions holders</Typography>
                         </React.Fragment>
                       }/>
                     </ListItem>
                     <ListItem alignItems="flex-start">
                       <ListItemIcon style={{ color: "#839AC6" }}>
                         <KeyboardArrowRightIcon />
                       </ListItemIcon>
                       <ListItemText className={classes.listItem}
                        secondary={
                          <React.Fragment>
                           <Typography
                             component="span"
                             variant="body2">Exclusive giveaways for holders</Typography>
                         </React.Fragment>
                       }/>
                      </ListItem>
                  </List>
                </div>
              </Grid>

            </Grid>
            {/*/row*/}

            {/* timeline item 4 */}
            <Grid container
              direction="row"
              justifyContent="flex-start"
              spacing={2}
            >
              <Grid item xs={1}>
                <div className="row">
                  <div className="col">&nbsp;</div>
                  <div className="col">&nbsp;</div>
                </div>
                <h5 className="m-2">
                  <span className="badge badge-pill ">&nbsp;</span>
                </h5>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    marginLeft: '10px',
                  }}
                  className="h-50"
                >
                  <div className="col border-left">&nbsp;</div>
                  <div className="col border-left">&nbsp;</div>
                </Box>
              </Grid>

              <Grid item xs={10}>
                <div className="card-body">
                  <h4 className="card-title">
                    <span>Phase 4</span>Utility
                  </h4>
                  <List>
                    <ListItem alignItems="flex-start">
                      <ListItemIcon sx={{ color: "#839AC6" }}>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText className={classes.listItem}
                        secondary={
                         <React.Fragment>
                           <Typography
                             component="span"
                             variant="body2">
                               Lion holders begin staking to earn <span className="pink">{TOKEN_NAME}</span>
                           </Typography>
                         </React.Fragment>
                       }/>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                       <ListItemIcon style={{ color: "#839AC6" }}>
                         <KeyboardArrowRightIcon />
                       </ListItemIcon>
                       <ListItemText className={classes.listItem}
                        secondary={
                          <React.Fragment>
                           <Typography
                             component="span"
                             variant="body2">Implementation of <span className="pink">{TOKEN_NAME}</span> token and game theory in the Fuji Lions ecosystem</Typography>
                         </React.Fragment>
                       }/>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                    <ListItemIcon style={{ color: "#839AC6" }}>
                    <KeyboardArrowRightIcon />
                    </ListItemIcon>
                    <ListItemText className={classes.listItem}
                         secondary={
                           <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2">More game theory and utility for <span className="pink">{TOKEN_NAME}</span> down the road</Typography>
                          </React.Fragment>
                        }/>
                    </ListItem>
                  </List>
                </div>
              </Grid>
            </Grid>
            {/*/row*/}

            {/* timeline item 4 */}
            <Grid container
              direction="row"
              justifyContent="flex-start"
              spacing={2}
            >
              <Grid item xs={1}>
                <div className="row">
                  <div className="col">&nbsp;</div>
                  <div className="col">&nbsp;</div>
                </div>
                <h5 className="m-2">
                  <span className="badge badge-pill ">&nbsp;</span>
                </h5>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    marginLeft: '10px',
                  }}
                  className="h-50"
                >
                  <div className="col border-left">&nbsp;</div>
                  <div className="col border-left">&nbsp;</div>
                </Box>
              </Grid>

              <Grid item xs={10}>
                <div className="card-body">
                  <h4 className="card-title">
                    <span>Phase 5</span>Metaverse Expansion
                  </h4>
                  <List>
                    <ListItem alignItems="flex-start">
                      <ListItemIcon sx={{ color: "#839AC6" }}>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText className={classes.listItem}
                        secondary={
                         <React.Fragment>
                           <Typography
                             component="span"
                             variant="body2">Expansion of Fuji Lions Game Theory into the metaverse</Typography>
                         </React.Fragment>
                       }/>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                    <ListItemIcon style={{ color: "#839AC6" }}>
                      <KeyboardArrowRightIcon />
                    </ListItemIcon>
                    <ListItemText className={classes.listItem}
                      secondary={
                        <React.Fragment>
                         <Typography
                           component="span"
                           variant="body2">We'll be buying our own metaverse land so that lions can continue to use their <span className="pink">{TOKEN_NAME}</span> in unique ways</Typography>
                       </React.Fragment>
                     }/>
                   </ListItem>
                  </List>
                </div>
              </Grid>
            </Grid>
            {/*/row*/}

            {/* end left side */}
          </Grid>

          <Grid item xs={4} marginTop={isMobile ? 0 : -50}>
            <img src={require("../layout/images/lions.gif").default} />
          </Grid>

        </Grid>
      </Container>
    </Grid>
  );
};

export default Roadmap;
