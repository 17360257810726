import styled from "styled-components";
import { FC, useCallback, useEffect, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import {
  Connection,
  PublicKey,
  TokenAmount,
  Transaction,
} from "@solana/web3.js";

import {
  ArweaveMetadata,
  FujiType,
  getMintedNftMeta,
  loadMetaUri,
} from "../../utils/nft";

import {
  asyncTxs,
  StakedData,
  mintNewbornTransaction,
  getTokenFunds,
  getMintedNewbornCount,
  getNewbornMeta,
} from "../../utils/staking";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { errorCodes } from "eth-rpc-errors/dist/error-constants";
import { ConnectButton } from "../../components/ConnectButton";
import {
  dateTimeFormatter,
  integerNumberFormat,
  IS_DEVNET,
  TOKEN_NAME,
} from "../../config";
import { shortenAddress } from "../../utils/candy-machine";
import ImageToken from "../../layout/images/fuji-token.png";
import { Close } from "@mui/icons-material";
import { formatRemaining } from "../../utils/time";
import { WalletHeader } from "../../components/WalletHeader";
import { fujiProgramId } from "../../utils/keys";

const Loading = styled.div`
  color: white;
  min-height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConfirmBreeding = ({
  mintingCost,
  open,
  onConfirm,
  onCancel,
}: {
  mintingCost: number;
  open: boolean;
  onConfirm: Function;
  onCancel: Function;
}) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={() => onCancel()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Proceed?
          <IconButton
            aria-label="close"
            onClick={() => onCancel()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Breeding a cub will consume <strong>{mintingCost}</strong>{" "}
            {TOKEN_NAME}.<br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onCancel()}>Disagree</Button>
          <Button onClick={() => onConfirm()} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const BreedingSucceeded = ({
  meta,
  open,
  onClose,
}: {
  meta: ArweaveMetadata;
  open: boolean;
  onClose: Function;
}) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={() => onClose()}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          Congratulations
          <IconButton
            aria-label="close"
            onClick={() => onClose()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="flex justify-center items-center flex-col">
            <div className="mb-4">An heir was born!</div>

            {meta && <img className="w-60" src={meta.image} />}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const TitleSection = ({
  walletAddress,
  mintedNewbornCount,
  tokenFunds,
}: {
  tokenFunds: TokenAmount | undefined;
  walletAddress?: PublicKey;
  mintedNewbornCount: number;
}) => {
  return (
    <WalletHeader title="Breeding" walletAddress={walletAddress}>
      <div className="font-semibold flex items-center">
        {mintedNewbornCount !== -1 && (
          <>
            {TOKEN_NAME}
            <img className="h-5 mx-2" src={ImageToken} alt="" />
            {integerNumberFormat.format(tokenFunds?.uiAmount ?? 0)}
          </>
        )}
        &nbsp;
      </div>
    </WalletHeader>
  );
};

const NftCardDiv = styled.div`
  border: 1px solid #5c4cb6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.selected {
    background-color: #5c4cb6;
  }
`;

const ONE_DAY = 24 * 60 * 60 * 1000;
const NftCard: FC<{
  arweaveMetadata: ArweaveMetadata;
  mint: string;
  onActionClick: (mint: string) => void;
  connection: any;
  insufficientFunds: any;
}> = ({
  arweaveMetadata,
  mint,
  onActionClick,
  connection,
  insufficientFunds,
}) => {
  const [breedable, setBreedable] = useState(false);
  const [time, setBredTime] = useState(0);
  const [loading, setLoading] = useState(true);
  const [now, setNow] = useState(Math.floor(Date.now() / 1000));

  useEffect(() => {
    //
    (async () => {
      //
      const gen0_key = new PublicKey(mint);
      const breed_key = (
        await PublicKey.findProgramAddress(
          [new Uint8Array([98, 105, 114, 116, 104]), gen0_key.toBuffer()],
          fujiProgramId
        )
      )[0];

      const breed_account = await connection.getAccountInfo(breed_key);

      if (breed_account) {
        const data = breed_account!.data!;
        let breed_time =
          data[0] + (data[1] << 8) + (data[2] << 16) + (data[3] << 24);

        setBredTime(breed_time);
      } else {
        setBreedable(true);
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (time) {
      let a = setTimeout(() => {
        if (now - time >= 60 * 60 * 24) {
          setBreedable(true);
          setBredTime(0);
        }
        setNow(now + 1);
      }, 1000);

      return () => clearTimeout(a);
    }
  }, [time, now]);

  const canInteract = breedable && !loading;

  return (
    <>
      <NftCardDiv className={"p-3 relative"} key={arweaveMetadata.name}>
        <h2 className="text-white text-1xl font-semibold mb-3 leading-4">
          {arweaveMetadata.name}
        </h2>
        <img className="rounded-md" src={arweaveMetadata.image} alt="" />
        <div className="relative w-full">
          <div className={canInteract ? "" : "opacity-0"}>
            <NftCardButton
              disabled={!canInteract || insufficientFunds}
              className="mt-4 block py-2 px-4 text-center w-full rounded-md primary-bg text-white"
              onClick={() => onActionClick(mint)}
            >
              {insufficientFunds ? <>You need more $FUJI!</> : <>Breed</>}
            </NftCardButton>
          </div>
          {!canInteract && (
            <div className="absolute text-sm inset-0 flex items-center justify-center">
              {loading ? (
                "loading..."
              ) : (
                <div className="text-center">
                  Breedable again in:
                  <br />
                  {formatRemaining(time * 1000 + ONE_DAY)}
                </div>
              )}
            </div>
          )}
        </div>
      </NftCardDiv>
    </>
  );
};

const NftCardButton = styled.button`
  background: #db4261;
  color: #fff;
  border-color: #fff;
  border-radius: 0;
  box-shadow: 2px 3px 0 #201c37;
  display: block;
  padding: 6px 16px;

  &[disabled] {
    opacity: 0.25;
    pointer-events: none;
  }
`;

const NftActionCards: FC<{
  metas: [StakedData, ArweaveMetadata][];
  emptyText: string;
  insufficientFunds: boolean;
  onActionClick: (mint: string) => void;
  connection: any;
}> = ({ metas, emptyText, onActionClick, insufficientFunds, connection }) => {
  return (
    <div>
      {metas.length > 0 ? (
        <>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
            {metas
              .sort(([, a], [, b]) => a.name.localeCompare(b.name))
              .map(([metadata, arweaveMeta]) => {
                return (
                  <NftCard
                    insufficientFunds={insufficientFunds}
                    key={metadata.mint}
                    mint={metadata.mint}
                    arweaveMetadata={arweaveMeta}
                    onActionClick={onActionClick}
                    connection={connection}
                  />
                );
              })}
          </div>
        </>
      ) : (
        <>
          <div className="text-gray-400 text-center py-20">{emptyText}</div>
        </>
      )}
    </div>
  );
};

const BreedSection = ({
  metas,
  breed,
  insufficientFunds,
  connection,
}: {
  metas: [StakedData, ArweaveMetadata][];
  breed: (mint: string) => unknown;
  insufficientFunds: boolean;
  connection: any;
}) => {
  return (
    <>
      <section id="my-nfts">
        <div className="container pb-5">
          <div className="mb-4">
            <NftActionCards
              insufficientFunds={insufficientFunds}
              metas={metas.filter(([m]) => m.is_lion)}
              emptyText={"You don't have any breedable NFTs 😟"}
              connection={connection}
              onActionClick={(mint) => {
                breed(mint);
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

const NEWBORN_COST = 150;
const calculateMintCost = (index: number) => {
  let price = NEWBORN_COST;
  if (Math.floor(index / 2500) > 0) {
    price *= Math.floor(index / 2500) + 1;
  }
  return price;
};

export const Breeding = ({ connection }: { connection: Connection }) => {
  const { enqueueSnackbar } = useSnackbar();
  const wallet = useWallet();
  const [loading, setLoading] = useState<string | null>(null);
  const [metas, setMetas] = useState<[StakedData, ArweaveMetadata][]>([]);
  const [tokenFunds, setTokenFunds] = useState<undefined | TokenAmount>(
    undefined
  );
  const [mintedNewbornCount, setMintedNewbornCount] = useState<number>(-1);
  const [insufficientFunds, setInsufficientFunds] = useState<boolean>(true);
  const [breedingSucceeded, setBreedingSucceeded] = useState<boolean>(false);
  const [newbornMeta, setNewbornMeta] = useState<ArweaveMetadata | undefined>(
    undefined
  );

  const [confirmMintBreeding, setConfirmMintBreeding] = useState<
    string | undefined
  >(undefined);

  const fetchMeta = useCallback(async () => {
    if (!wallet) return;
    if (!wallet.publicKey) return;

    setLoading("Loading FUJIs");
    const metas = await getMintedNftMeta(connection, wallet.publicKey!);

    const mintedCount = await getMintedNewbornCount(connection);
    setMintedNewbornCount(mintedCount);

    setNewbornMeta(await getNewbornMeta());

    const tokenFunds = await getTokenFunds(connection, wallet.publicKey!);
    setTokenFunds(tokenFunds);
    setInsufficientFunds(
      calculateMintCost(mintedCount) > (tokenFunds?.uiAmount ?? 0)
    );

    setLoading(null);
    setMetas(
      metas.map(([meta, arweave, type]) => [
        {
          mint: meta.mint,
          is_cub: type === FujiType.Cub,
          is_dark: type === FujiType.DarkCub,
          is_newborn: type === FujiType.Newborn,
          is_lion: type === FujiType.Gen0,
          time_last_fed: -1,
          time_staked: -1,
          local_claim: BigInt(-1),
          uri: meta.data.uri,
          feed_count: -1,
        },
        arweave,
      ])
    );
  }, [wallet]);

  useEffect(() => {
    fetchMeta();
  }, [fetchMeta]);

  const sendInstructions = useCallback(
    async (transaction: Transaction) => {
      await wallet.signTransaction(transaction);

      return await wallet.sendTransaction(transaction, connection, {
        skipPreflight: IS_DEVNET,
      });
    },
    [connection, wallet?.publicKey, wallet.sendTransaction]
  );

  const handleStakeUnStakeError = (message: string, error: any) => {
    if (error?.error?.code === errorCodes.provider.userRejectedRequest) return;
    const errorMessage = error?.message ?? undefined;
    enqueueSnackbar(
      `Sorry there was an error breeding your NFT. Please try again. ${
        errorMessage ? `Reason: ${errorMessage}` : ""
      }`,

      {
        variant: "error",
      }
    );
  };

  const breed = useCallback(
    async (mint: string) => {
      try {
        const [tx, signers] = await mintNewbornTransaction(
          mint,
          wallet.publicKey,
          connection
        );
        setLoading("Waiting for breeding confirmation");
        await asyncTxs([tx], connection, wallet, signers);
        enqueueSnackbar("Bred successfully", {
          variant: "success",
        });
        setBreedingSucceeded(true);
        await fetchMeta();
        setLoading(null);
      } catch (error: any) {
        setLoading(null);
        handleStakeUnStakeError(
          "Sorry there was an error when breeding. Please try again.",
          error
        );
        throw error;
      }
    },
    [connection, wallet?.publicKey, sendInstructions]
  );

  const pageContent = (
    <>
      {loading ? (
        <Loading>
          <div className="flex flex-col items-center">
            <CircularProgress color="inherit" />
            <div className="mt-4">{loading}</div>
          </div>
        </Loading>
      ) : (
        <BreedSection
          insufficientFunds={insufficientFunds}
          metas={metas}
          breed={(mint) => setConfirmMintBreeding(mint)}
          connection={connection}
        />
      )}
    </>
  );

  return (
    <>
      <TitleSection
        walletAddress={wallet?.publicKey}
        tokenFunds={tokenFunds}
        mintedNewbornCount={mintedNewbornCount}
      />
      <>
        <ConfirmBreeding
          mintingCost={calculateMintCost(mintedNewbornCount)}
          open={typeof confirmMintBreeding === "string"}
          onConfirm={() => {
            breed(confirmMintBreeding);
            setConfirmMintBreeding(undefined);
          }}
          onCancel={() => setConfirmMintBreeding(undefined)}
        />
        <BreedingSucceeded
          meta={newbornMeta}
          open={breedingSucceeded}
          onClose={() => setBreedingSucceeded(false)}
        />
      </>
      {wallet?.publicKey ? (
        <>{pageContent}</>
      ) : (
        <section>
          <div className="container">
            <div className="md:flex items-center flex-row justify-center">
              <ConnectButton>Connect</ConnectButton>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
