import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { numberFormat, TOKEN_NAME } from "../../config";

export const DialogConfirmRegularCubClaim = ({
  data,
  onConfirm,
  onCancel,
}: {
  data: { mint: string[]; fuji: number };
  onConfirm: (data: string[]) => void;
  onCancel: Function;
}) => {
  return (
    <>
      <Dialog
        open={!!data}
        onClose={() => onCancel()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Claim {TOKEN_NAME}
          <IconButton
            aria-label="close"
            onClick={() => onCancel()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {data && (
              <>
                <div className="mb-3">
                  20% of your claimable {TOKEN_NAME} will be taxed and
                  distributed to Evil Cub holders.
                </div>
                <div className="mb-3">
                  Currently you can claim {numberFormat.format(data.fuji * 0.8)}{" "}
                  {TOKEN_NAME} of {numberFormat.format(data.fuji)} {TOKEN_NAME}.
                </div>
                <div className="mb-3">
                  {numberFormat.format(data.fuji * 0.2)} {TOKEN_NAME} will be
                  distributed to Evil Cub holders.
                </div>
                <div>Are you sure you want to claim?</div>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => onCancel()}
            autoFocus
            color="error"
            variant="contained"
          >
            No
          </Button>
          <Button
            onClick={() => onConfirm(data.mint)}
            color="success"
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
