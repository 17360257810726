export const timeToTarget = (targetDate: Date) => {
  const delta = targetDate.getTime() - Date.now();
  if (delta <= 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }

  return {
    days: Math.floor(delta / (1000 * 60 * 60 * 24)),
    hours: Math.floor((delta / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((delta / 1000 / 60) % 60),
    seconds: Math.floor((delta / 1000) % 60),
  };
};

const digit = (digit: number) => `${digit}`.padStart(2, "0");
export const formatRemaining = (target: number) => {
  const remaining = timeToTarget(new Date(target));

  const formattedDays = remaining.days > 0 ? `${digit(remaining.days)}d ` : ``;

  return `${formattedDays}${digit(remaining.hours)}h ${digit(
    remaining.minutes
  )}m ${digit(remaining.seconds)}s`;
};
