import styled from "styled-components";
import { FC, useCallback, useEffect, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import {
  ArweaveMetadata,
  FujiType,
  getGlobalu64,
  getMintedNftMeta,
  loadMetaUri,
} from "../../utils/nft";
import { Connection, PublicKey, Transaction } from "@solana/web3.js";
import {
  asyncTxs,
  calculateRewards,
  claimOnWorker,
  countStakedNfts,
  createClaimTaxTransactions,
  createClaimTransactions,
  createStakeTransactions,
  createUnstakeTransactions,
  StakedData,
  StakeMintType,
  testGPA,
} from "../../utils/staking";
import { CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { errorCodes } from "eth-rpc-errors/dist/error-constants";
import { ConnectButton } from "../../components/ConnectButton";
import {
  dateTimeFormatter,
  IS_DEVNET,
  numberFormat,
  TOKEN_NAME,
} from "../../config";
import { WalletHeader } from "../../components/WalletHeader";
import { DialogConfirmRegularCubUnstake } from "./DialogConfirmRegularCubUnstake";
import { DialogConfirmRegularCubClaim } from "./DialogConfirmRegularCubClaim";
import { DialogCubStolen } from "./DialogCubStolen";
import Icon from "@mdi/react";
import { mdiHelpCircleOutline } from "@mdi/js";

const TabDiv = styled.div`
  flex: 1;
  text-align: center;
  padding: 10px;
  text-transform: uppercase;
  transition: background-color 0.2s, color 0.2s;
  cursor: pointer;
`;

const Loading = styled.div`
  color: white;
  min-height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

enum StakeTab {
  genesisLion = 0,
  regularLionCub = 1,
  evilLionCub = 2,
}

const Tab: FC<{ active: boolean; onClick: Function }> = ({
  active,
  onClick,
  children,
}) => {
  return (
    <TabDiv
      role="button"
      onClick={() => onClick()}
      className={
        "text-base border-2 pl-2 pr-2 rounded-sm cursor-pointer rounded-md " +
        (active
          ? "text-white font-black border-white"
          : "text-gray-400 hover:text-white border-transparent")
      }
    >
      {children}
    </TabDiv>
  );
};

const TitleSection = ({
  walletAddress,
  stakingCounts,
}: {
  walletAddress?: PublicKey;
  stakingCounts:
    | undefined
    | {
        total: number;
        lions: number;
        lionCubs: number;
        darkCubs: number;
      };
}) => {
  return (
    <>
      <WalletHeader title="Staking" walletAddress={walletAddress}>
        <div className="font-semibold">
          {stakingCounts && (
            <>
              <Tooltip
                title={
                  <>
                    <div>Genesis Lions: {stakingCounts.lions}</div>
                    <div>Regular Cubs: {stakingCounts.lionCubs}</div>
                    <div>Evil Cubs: {stakingCounts.darkCubs}</div>
                  </>
                }
              >
                <div className="flex items-center cursor-help">
                  <span>{stakingCounts.total} Lions staked</span>
                  <Icon className="ml-1" path={mdiHelpCircleOutline} size={1} />
                </div>
              </Tooltip>
            </>
          )}
          &nbsp;
        </div>
      </WalletHeader>
    </>
  );
};

const TabSection = ({
  selectedTab,
  onTabChange,
}: {
  selectedTab: number;
  onTabChange: (idx: number) => void;
}) => {
  return (
    <section>
      <div className="container mb-4">
        <div className="grid grid-cols-3 gap-3">
          <Tab
            active={selectedTab === StakeTab.genesisLion}
            onClick={() => onTabChange(StakeTab.genesisLion)}
          >
            Genesis Lions
          </Tab>
          <Tab
            active={selectedTab === StakeTab.regularLionCub}
            onClick={() => onTabChange(StakeTab.regularLionCub)}
          >
            Regular Lion Cub
          </Tab>
          <Tab
            active={selectedTab === StakeTab.evilLionCub}
            onClick={() => onTabChange(StakeTab.evilLionCub)}
          >
            Evil Lion Cub
          </Tab>
        </div>
      </div>
    </section>
  );
};

const NftCardDiv = styled.div`
  border: 1px solid #5c4cb6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.selected {
    background-color: #5c4cb6;
  }
`;

const NftCard: FC<{
  arweaveMetadata: ArweaveMetadata;
  selected: boolean;
  hasSelection: boolean;
}> = ({ hasSelection, arweaveMetadata, selected, children }) => {
  return (
    <>
      <NftCardDiv
        className={
          "p-3 relative " +
          (selected ? "selected" : "") +
          (hasSelection && !selected ? " opacity-50" : "")
        }
        key={arweaveMetadata.name}
      >
        <h2 className="text-white text-1xl font-semibold mb-3 leading-4">
          {arweaveMetadata.name}
        </h2>
        <img className="rounded-md" src={arweaveMetadata.image} alt="" />
        {children}
      </NftCardDiv>
    </>
  );
};

const NftCardButton = styled.button`
  background: #db4261;
  color: #fff;
  border-color: #fff;
  border-radius: 0;
  box-shadow: 2px 3px 0 #201c37;
  display: block;
  padding: 6px 16px;

  &[disabled] {
    opacity: 0.25;
    pointer-events: none;
  }
`;

const NftActionCards: FC<{
  metas: [StakedData, ArweaveMetadata][];
  emptyText: string;
  actionText: string;
  onWithdrawRewardsClick?: (metadata: StakedData) => void;
  onWithdrawTaxesClick?: (metadata: StakedData) => void;
  onActionClick: (metadata: StakedData) => void;
  selection: string[];
  globalu64: bigint;
}> = ({
  onWithdrawRewardsClick,
  onWithdrawTaxesClick,
  metas,
  emptyText,
  actionText,
  onActionClick,
  selection,
  globalu64,
}) => {
  const now = Math.floor(Date.now() / 1000);

  return (
    <div>
      {metas.length > 0 ? (
        <>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
            {metas
              .sort(([, a], [, b]) => a.name.localeCompare(b.name))
              .map(([metadata, arweaveMeta]) => {
                const canInteract = isUnstakeOrClaimable(metadata);
                const is_dark_cub = metadata.is_dark && metadata.is_cub;
                const canClaimTax =
                  globalu64 > metadata.local_claim && is_dark_cub;

                return (
                  <NftCard
                    key={metadata.mint}
                    arweaveMetadata={arweaveMeta}
                    hasSelection={selection.length > 0}
                    selected={selection.indexOf(metadata.mint) > -1}
                  >
                    <div className="relative">
                      {!metadata.is_dark && (
                        <div className={canInteract ? "" : "opacity-0"}>
                          <NftCardButton
                            disabled={!canInteract}
                            className="mt-4 block py-2 px-4 text-center w-full rounded-md primary-bg text-white"
                            onClick={() => onActionClick(metadata)}
                          >
                            {actionText}
                          </NftCardButton>
                          {onWithdrawRewardsClick && (
                            <NftCardButton
                              disabled={!canInteract}
                              className="mt-4 block py-2 px-4 text-center w-full rounded-md primary-bg text-white"
                              onClick={() => onWithdrawRewardsClick(metadata)}
                            >
                              Claim{" "}
                              {numberFormat.format(
                                calculateRewards(metadata, now)
                              )}{" "}
                              {TOKEN_NAME}
                            </NftCardButton>
                          )}
                        </div>
                      )}
                      {!canInteract && (
                        <div className="absolute text-sm inset-0 flex items-center justify-center">
                          Your lion is locked up for staking until{" "}
                          {dateTimeFormatter.format(
                            new Date(metadata.time_staked * 1000 + T24h)
                          )}
                        </div>
                      )}
                    </div>
                    {metadata.is_dark && onWithdrawTaxesClick && (
                      <>
                        <NftCardButton
                          disabled={!canInteract}
                          className="mt-4 block py-2 px-4 text-center w-full rounded-md primary-bg text-white"
                          onClick={() => onActionClick(metadata)}
                        >
                          {actionText}
                        </NftCardButton>
                        <NftCardButton
                          disabled={!canClaimTax}
                          className="mt-4 block py-2 px-4 text-center rounded-md primary-bg text-white"
                          onClick={() => onWithdrawTaxesClick(metadata)}
                        >
                          Claim Evil Cub Tax (
                          {numberFormat.format(
                            Number(globalu64 - metadata.local_claim) /
                              10 ** (IS_DEVNET ? 9 : 3)
                          )}
                          )
                        </NftCardButton>
                      </>
                    )}
                  </NftCard>
                );
              })}
          </div>
        </>
      ) : (
        <>
          <div className="text-gray-400 text-center py-20">{emptyText}</div>
        </>
      )}
    </div>
  );
};

const HeaderButton = ({
  disabled = false,
  cb,
  text,
}: {
  disabled?: boolean;
  cb: () => void;
  text: string;
}) => {
  return (
    <>
      <button
        disabled={disabled}
        onClick={() => {
          cb();
        }}
        className={
          "ml-8 text-base border-2 pl-2 pr-2 border-white rounded-sm cursor-pointer select-none " +
          (disabled
            ? "opacity-50 cursor-not-allowed"
            : "opacity-90 hover:opacity-100")
        }
      >
        {text}
      </button>
    </>
  );
};

const SelectAll = ({
  metas,
  actions,
  selected,
  onSelect,
  onDeselect,
  disabled = false,
  selectText = "Select All",
}: {
  metas: [StakedData, ArweaveMetadata][];
  actions: JSX.Element;
  selected: string[];
  onSelect: () => void;
  onDeselect: () => void;
  disabled: boolean;
  selectText?: string;
}) => {
  const hasSelection = selected.length > 0;

  return (
    <>
      <HeaderButton
        cb={hasSelection ? onDeselect : onSelect}
        text={hasSelection ? "Deselect All" : selectText}
        disabled={disabled}
      />
      {actions}
    </>
  );
};

const T24h = 1000 * 60 * 60 * 24;
//const T24h = 10 * 1000;
const isUnstakeOrClaimable = (meta: StakedData) => {
  if (meta.time_staked === -1) return true;

  return Date.now() - meta.time_staked * 1000 > T24h;
};

const tabStakedFilter = (data: StakedData, selectedTab: StakeTab) => {
  switch (selectedTab) {
    case StakeTab.genesisLion:
      return data.is_lion;
    case StakeTab.regularLionCub:
      return data.is_cub && data.is_dark === false;
    case StakeTab.evilLionCub:
      return data.is_dark;
  }
};

const tabMintType = (selectedTab: StakeTab): StakeMintType =>
  selectedTab === StakeTab.genesisLion
    ? StakeMintType.gen0
    : StakeMintType.gen1_4;

const NftSection = ({
  tabType,
  mintType,
  metas,
  stake,
  stakedMetas,
  unstake,
  confirmUnstake,
  withdrawRewards,
  withdrawTaxes,
  confirmWithdrawRewards,
  globalu64,
}: {
  tabType: StakeTab;
  mintType: StakeMintType;
  metas: [StakedData, ArweaveMetadata][];
  stakedMetas: [StakedData, ArweaveMetadata][];
  stake: (mints: string[], mint_type: number) => unknown;
  unstake: (mints: string[], mint_type: number) => unknown;
  confirmUnstake: (mints: string[]) => unknown;
  withdrawRewards: (mints: string[], mint_type: number) => unknown;
  withdrawTaxes: (mints: string[]) => unknown;
  confirmWithdrawRewards: (data: { mint: string[]; fuji: number }) => unknown;
  globalu64: bigint;
}) => {
  const [staked_selection, setStakedSelection] = useState<string[]>([]);
  const [unstaked_selection, setUnstakedSelection] = useState<string[]>([]);

  const hasBulkOperations = tabType !== StakeTab.regularLionCub;

  const getStakableLions = () => {
    return metas.map(([data]) => data.mint);
  };
  const getUnstakableLions = () => {
    return stakedMetas
      .filter(([meta]) => isUnstakeOrClaimable(meta))
      .map(([meta]) => meta.mint);
  };

  const unstakedActions = (
    <>
      <HeaderButton
        disabled={unstaked_selection.length === 0}
        text="Stake"
        cb={() => {
          stake(unstaked_selection, mintType);
        }}
      />
    </>
  );

  const stakedActions = (
    <>
      <HeaderButton
        text="Claim"
        disabled={staked_selection.length === 0}
        cb={() => {
          withdrawRewards(staked_selection, mintType);
        }}
      />
      <HeaderButton
        text="Unstake"
        disabled={staked_selection.length === 0}
        cb={() => {
          unstake(staked_selection, mintType);
        }}
      />
    </>
  );

  return (
    <>
      <section id="my-nfts">
        <div className="container pb-5">
          <div className="text-3xl font-semibold mb-4 sm:flex flex-row items-center">
            <h2>Unstaked</h2>
            <SelectAll
              metas={metas}
              actions={unstakedActions}
              selected={unstaked_selection}
              disabled={getStakableLions().length === 0}
              onSelect={() => {
                setUnstakedSelection(getStakableLions());
              }}
              onDeselect={() => {
                setUnstakedSelection([]);
              }}
            />
          </div>
          <div className="mb-4">
            <NftActionCards
              selection={unstaked_selection}
              metas={metas}
              actionText="Stake"
              emptyText={"You don't have any unstaked NFTs 😟"}
              onActionClick={(metadata) => {
                stake([metadata.mint], mintType);
              }}
              globalu64={globalu64}
            />
          </div>

          <div className="text-3xl font-semibold pt-4 mb-4 sm:flex flex-row items-center">
            <h2>Staked</h2>
            {hasBulkOperations && (
              <SelectAll
                metas={metas}
                actions={stakedActions}
                selected={staked_selection}
                selectText="Select All Eligible"
                disabled={getUnstakableLions().length === 0}
                onSelect={() => {
                  setStakedSelection(getUnstakableLions());
                }}
                onDeselect={() => {
                  setStakedSelection([]);
                }}
              />
            )}
          </div>
          <div className="mb-4">
            <NftActionCards
              selection={staked_selection}
              metas={stakedMetas}
              globalu64={globalu64}
              actionText="Unstake"
              emptyText={"You don't have any staked NFTs 😟"}
              onActionClick={(metadata) => {
                if (metadata.is_cub && metadata.is_dark === false) {
                  confirmUnstake([metadata.mint]);
                } else {
                  unstake([metadata.mint], mintType);
                }
              }}
              onWithdrawRewardsClick={(metadata) => {
                if (metadata.is_cub && metadata.is_dark === false) {
                  confirmWithdrawRewards({
                    mint: [metadata.mint],
                    fuji: calculateRewards(
                      metadata,
                      Math.floor(Date.now() / 1000)
                    ),
                  });
                } else {
                  withdrawRewards([metadata.mint], mintType);
                }
              }}
              onWithdrawTaxesClick={(metadata) => {
                if (metadata.is_dark) {
                  withdrawTaxes([metadata.mint]);
                }
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export const Staking = ({ connection }: { connection: Connection }) => {
  const { enqueueSnackbar } = useSnackbar();
  const wallet = useWallet();
  const [selectedTab, setSelectedTab] = useState(StakeTab.genesisLion);
  const [loading, setLoading] = useState<string | null>(null);
  const [dialogCubStolen, setDialogCubStolen] = useState<boolean>(false);
  const [globalu64, setGlobalU64] = useState<bigint>(BigInt(-1));
  const [stakedNftCount, setStakedNftCount] = useState<
    | {
        total: number;
        lions: number;
        lionCubs: number;
        darkCubs: number;
      }
    | undefined
  >(undefined);
  const [confirmRegularCubUnstake, setConfirmRegularCubUnstake] = useState<
    string[] | undefined
  >(undefined);
  const [confirmRegularCubClaim, setConfirmRegularCubClaim] = useState<
    { mint: string[]; fuji: number } | undefined
  >(undefined);
  const [metas, setMetas] = useState<[StakedData, ArweaveMetadata][]>([]);
  const [stakedMetas, setStakedMetas] = useState<
    [StakedData, ArweaveMetadata][]
  >([]);

  const fetchMeta = useCallback(async () => {
    if (!wallet) return;
    if (!wallet.publicKey) return;

    setLoading("Loading FUJIs");
    const metas = await getMintedNftMeta(connection, wallet.publicKey!);

    const gpa = await testGPA(connection, wallet.publicKey.toBase58());

    setStakedNftCount(await countStakedNfts(connection));

    setGlobalU64(await getGlobalu64(connection));

    const stakedData = (
      await Promise.all(
        gpa.map((data) =>
          loadMetaUri(data.uri)
            .then(
              (arweaveData) =>
                [data, arweaveData] as [StakedData, ArweaveMetadata]
            )
            .catch(() => null)
        )
      )
    ).filter(Boolean);
    // setRewards(
    //   stakedData.reduce(
    //     (sum, [staked]) => sum + calculateRewards(staked, new Date()),
    //     0
    //   )
    // );
    setStakedMetas(stakedData);
    setLoading(null);
    setMetas(
      metas.map(([meta, arweave, type]) => [
        {
          mint: meta.mint,
          is_cub: type === FujiType.Cub,
          is_dark: type === FujiType.DarkCub,
          is_newborn: type === FujiType.Newborn,
          is_lion: type === FujiType.Gen0,
          time_last_fed: -1,
          time_staked: -1,
          local_claim: BigInt(-1),
          uri: meta.data.uri,
          feed_count: -1,
        },
        arweave,
      ])
    );
  }, [wallet]);

  useEffect(() => {
    fetchMeta();
  }, [fetchMeta]);

  const sendInstructions = useCallback(
    async (transaction: Transaction) => {
      return await wallet.sendTransaction(transaction, connection, {
        skipPreflight: IS_DEVNET,
      });
    },
    [connection, wallet?.publicKey, wallet.sendTransaction]
  );

  const handleStakeUnStakeError = (message: string, error: any) => {
    if (error?.error?.code === errorCodes.provider.userRejectedRequest) return;
    const errorMessage = error?.message ?? undefined;
    enqueueSnackbar(
      `Sorry there was an error when staking your NFT. Please try again. ${
        errorMessage ? `Reason: ${errorMessage}` : ""
      }`,

      {
        variant: "error",
      }
    );
  };

  const stake = useCallback(
    async (unstaked_selection: string[], mint_type: number) => {
      try {
        const txs = await createStakeTransactions(
          unstaked_selection,
          wallet.publicKey,
          mint_type,
          connection
        );
        setLoading("Waiting for staking confirmation");
        await asyncTxs(txs, connection, wallet);
        enqueueSnackbar("Staked successfully", {
          variant: "success",
        });
        await fetchMeta();
        setLoading(null);
      } catch (error: any) {
        setLoading(null);
        handleStakeUnStakeError(
          "Sorry there was an error when staking. Please try again.",
          error
        );
      }
    },
    [connection, wallet?.publicKey, sendInstructions]
  );

  const unstake = useCallback(
    async (unstaked_selection: string[], mint_type: number) => {
      if (!wallet.publicKey) return;
      try {
        const txs = await createUnstakeTransactions(
          unstaked_selection,
          wallet.publicKey,
          mint_type,
          connection
        );
        setLoading("Waiting for unstaking confirmation");
        if (selectedTab === StakeTab.regularLionCub) {
          // is regular cub
          txs.forEach((e) => {
            e.instructions.forEach((i) => (i.keys[11].isSigner = true));
          });
          const { result } = await claimOnWorker(connection, txs, wallet);

          if (result === "stolen") {
            setDialogCubStolen(true);
          }
        } else {
          await asyncTxs(txs, connection, wallet);
        }
        enqueueSnackbar("Unstaked successfully", {
          variant: "success",
        });
        await fetchMeta();
        setLoading(null);
      } catch (error: any) {
        setLoading(null);
        handleStakeUnStakeError(
          "Sorry there was an error when unstaking. Please try again.",
          error
        );
      }
    },
    [connection, wallet.publicKey, sendInstructions, selectedTab]
  );

  const withdrawRewards = useCallback(
    async (unstaked_selection: string[], mint_type: number) => {
      if (!wallet.publicKey) return;
      try {
        const txs = await createClaimTransactions(
          unstaked_selection,
          wallet.publicKey,
          mint_type,
          connection
        );
        setLoading("Waiting for unstaking confirmation");
        await asyncTxs(txs, connection, wallet);
        enqueueSnackbar("Unstaked successfully", {
          variant: "success",
        });
        await fetchMeta();
        setLoading(null);
      } catch (error: any) {
        setLoading(null);
        handleStakeUnStakeError(
          "Sorry there was an error when unstaking. Please try again.",
          error
        );
      }
    },
    [connection, wallet.publicKey, sendInstructions]
  );

  const withdrawTaxes = useCallback(
    async (staked_selection: string[]) => {
      if (!wallet.publicKey) return;
      try {
        const txs = await createClaimTaxTransactions(
          staked_selection,
          wallet.publicKey,
          connection
        );
        setLoading("Waiting for taxing confirmation");
        await asyncTxs(txs, connection, wallet);
        enqueueSnackbar("Withdrawing taxes succeeded", {
          variant: "success",
        });
        await fetchMeta();
        setLoading(null);
      } catch (error: any) {
        setLoading(null);
        handleStakeUnStakeError(
          "Sorry there was an error when trying to withdraw your taxes. Please try again.",
          error
        );
      }
    },
    [connection, wallet.publicKey, sendInstructions]
  );

  const pageContent = (
    <>
      {loading ? (
        <Loading>
          <div className="flex flex-col items-center">
            <CircularProgress color="inherit" />
            <div className="mt-4">{loading}</div>
          </div>
        </Loading>
      ) : (
        <NftSection
          tabType={selectedTab}
          mintType={tabMintType(selectedTab)}
          globalu64={globalu64}
          metas={metas.filter(([m]) => tabStakedFilter(m, selectedTab))}
          stakedMetas={stakedMetas.filter(([m]) =>
            tabStakedFilter(m, selectedTab)
          )}
          stake={stake}
          unstake={unstake}
          confirmUnstake={(mint) => setConfirmRegularCubUnstake(mint)}
          confirmWithdrawRewards={(data) => setConfirmRegularCubClaim(data)}
          withdrawRewards={withdrawRewards}
          withdrawTaxes={withdrawTaxes}
        />
      )}
    </>
  );

  return (
    <>
      <TitleSection
        stakingCounts={stakedNftCount}
        walletAddress={wallet?.publicKey}
      />
      <>
        <DialogCubStolen
          open={dialogCubStolen}
          onClose={() => setDialogCubStolen(false)}
        />
        <DialogConfirmRegularCubClaim
          data={confirmRegularCubClaim}
          onCancel={() => setConfirmRegularCubClaim(undefined)}
          onConfirm={(data) => {
            withdrawRewards(data, tabMintType(selectedTab));
            setConfirmRegularCubClaim(undefined);
          }}
        />
        <DialogConfirmRegularCubUnstake
          data={confirmRegularCubUnstake}
          onCancel={() => setConfirmRegularCubUnstake(undefined)}
          onConfirm={(data) => {
            unstake(data, tabMintType(selectedTab));
            setConfirmRegularCubUnstake(undefined);
          }}
        />
      </>
      {wallet?.publicKey ? (
        <>
          {
            <TabSection
              selectedTab={selectedTab}
              onTabChange={(idx) => setSelectedTab(idx)}
            />
          }
          {pageContent}
        </>
      ) : (
        <section>
          <div className="container">
            <div className="md:flex items-center flex-row justify-center">
              <ConnectButton>Connect</ConnectButton>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
