import { createTheme } from "@mui/material";

export default createTheme({
  palette: {
    mode: "dark",
  },
  typography: {
    allVariants: {
      fontFamily: "'Space Mono', serif",
    },
    h1: {
      fontSize: "40px",
    },
    h2: {
      fontWeight: "bold",
      fontSize: "2rem",
    },
    h3: {
      fontSize: "26px",
    },
  },
});
