export const numberFormat = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 3,
});
export const integerNumberFormat = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 0,
});

export const dateTimeFormatter = new Intl.DateTimeFormat(undefined, {
  year: 'numeric',
  day: 'numeric',
  month: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
});

export const TOKEN_NAME = '$FUJI';

export const IS_DEVNET = process.env.REACT_APP_IS_DEVNET === "true";