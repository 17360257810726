import { PublicKey } from "@solana/web3.js";
import { FC } from "react";
import { shortenAddress } from "../utils/candy-machine";
import { ConnectButton } from "./ConnectButton";

export const WalletHeader: FC<{
  title: string;
  walletAddress: PublicKey | undefined;
}> = ({ walletAddress, children, title }) => {
  return (
    <>
      <section>
        <div className="container py-5 md:py-20">
          <div className="md:flex items-center flex-row justify-between">
            <div>
              <h2 className="text-4xl font-semibold">
                <div className="text-white">{title}</div>
              </h2>
              {children}
            </div>
            <div>
              <div className="md:text-right mb-2">
                {walletAddress && (
                  <>{shortenAddress(walletAddress.toBase58(), 8)}</>
                )}
                &nbsp;
              </div>
              <ConnectButton>Select different wallet</ConnectButton>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
