import * as React from "react";
import { useMemo, useState } from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { makeStyles } from "@mui/styles";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import {
  getSlopeWallet,
  getLedgerWallet,
  getMathWallet,
  getPhantomWallet,
  getSolflareWallet,
  getSolletWallet,
} from "@solana/wallet-adapter-wallets";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import Minter from "../components/Minter";
import PoweredBy from "../components/PoweredBy";

import WhitePaper from "./WhitePaper";
import Tokenomics from "./Tokenomics";
import Roadmap from "./Roadmap";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {TOKEN_NAME} from "../config";

const candyMachineId = process.env.REACT_APP_CANDY_MACHINE_ID
  ? new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID)
  : undefined;

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const useStyles = makeStyles((theme) => ({
  Card: {
    width: "auto",
    margin: "auto",
  },
  Media: {
    height: "100%",
    width: "100%",
  },
  listItem: {
    fontSize: ".9em",
    color: "#9FB5DE",
    marginTop: "9px !important",
    marginLeft: "-25px !important",
  },
}));

const LandingPage = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container direction="column">
        {/* what are fuji lions */}
        <Grid
          container
          className="what-is-fuji-lion"
          justifyContent="center"
          textAlign="center"
        >
          <Grid item md={7}>
            <Card
              sx={{
                padding: 5,
                background: "none",
              }}
              elevation={0}
            >
              <CardContent
                sx={{
                  color: "#FFF",
                }}
              >
                <Box sx={{ mb: 4 }}>
                  <Typography variant="h1">
                    What are <span className="pink">Fuji Lions</span>?
                  </Typography>
                </Box>
                <Box sx={{ mb: 4 }}>
                  <Typography className="lead" variant="body2">
                    A collection of <span className="pink">1,111</span> Solana
                    NFTs bringing <span className="pink">{TOKEN_NAME}</span> to the
                    blockchain. Fuji Lions combines game theory, utility, and
                    breeding all in one to create a risk-based game that
                    tremendously rewards its holders.
                  </Typography>
                </Box>
                <Minter
                  candyMachineId={candyMachineId}
                  connection={connection}
                  startDate={startDateSeed}
                  txTimeout={txTimeout}
                  rpcHost={rpcHost}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* end what are fuji lions */}

        <img
          width="100%"
          src={require("../layout/images/lions.png").default}
          alt="fuji lions"
        />

        {/* roadmap-section */}
        <Roadmap />
        {/* end roadmap-section */}

        {/* whitepaper */}
        <WhitePaper />
        {/* end whitepaper */}

        {/* tokenomics */}
        <Tokenomics />
        {/* end tokenomics */}

        <div className="footer p-5">
          <div className="container">
            <div className="row">
              <div className="col">
                <p className="text-center ">© 2021 FujiLions</p>
                <PoweredBy />
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Box>
  );
};

export default LandingPage;
