import {
  AccountMeta,
  Commitment,
  Connection,
  Keypair,
  PublicKey,
  sendAndConfirmRawTransaction,
  SignatureStatus,
  Signer,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  TokenAmount,
  Transaction,
  TransactionInstruction,
  TransactionSignature,
} from "@solana/web3.js";
import { Buffer } from "buffer";
import { loadMetaUri } from "./nft";
import { fujiProgramId } from "./keys";
import { retry } from "./promise";
import { WalletContextState } from "@solana/wallet-adapter-react";
import { IS_DEVNET } from "../config";

const {
  MintLayout,
  AccountLayout,
  Token,
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} = require("@solana/spl-token");

//export const program_id = new PublicKey("JW9CMKpw3p7LAeYNGD5ad41mZwshdCHTNcuSDmwzr9y");
export const program_id = new PublicKey(
  "mKawY7pPTpz4cXkfZB1gH9YvW2VXPLFV8QAn29Vbeik"
);
const reward_mint = new PublicKey(
  "DJWf1ZZM2UZ26CNg6e7kKhvzuy4p9FtvxhtL19qVp75x"
);
const candyMachine = new PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID!);

function createInstructionData(instruction: string): Buffer {
  if (instruction === "Stake") return Buffer.from([1]);
  else if (instruction === "Unstake") return Buffer.from([2]);
  else if (instruction === "StakeWithdraw") return Buffer.from([5]);

  throw new Error(`Unrecognized instruction: ${instruction}`);
}

export function getAssociatedTokenAddress(
  walletAddress: PublicKey,
  tokenAddress: PublicKey,
  allowOffCurve = false
) {
  return Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    tokenAddress,
    walletAddress,
    allowOffCurve
  );
}

function transactionKey(
  pubkey: PublicKey,
  isSigner: boolean,
  isWritable: boolean = true
): AccountMeta {
  return {
    pubkey,
    isSigner,
    isWritable,
  };
}

export function parseUint64Le(data: Uint8Array, offset: number = 0): bigint {
  let number = BigInt(0);
  for (let i = 0; i < 8; i++)
    number += BigInt(data[offset + i]) << BigInt(i * 8);
  return number;
}

export interface RewardData {
  minPeriod: bigint;
  rewardPeriod: bigint;
}

export async function getRewardData(
  connection: Connection
): Promise<RewardData> {
  let vaultAddress = await getVaultAddress();
  let valutAccountInfo = await connection.getAccountInfo(vaultAddress);
  if (!valutAccountInfo) throw new Error(`${vaultAddress} not initialized`);
  let { data } = valutAccountInfo;

  return {
    minPeriod: parseUint64Le(data, 0),
    rewardPeriod: parseUint64Le(data, 8),
  };
}

export function calculateRewards(stakedData: StakedData, now: number): number {
  if (!stakedData) return 0;

  const modifier = stakedData.is_lion ? 10 : 1;
  return (modifier * (now - stakedData.time_staked)) / (60 * 60 * 24);
}

const VAULT_PREFIX = "vault";
export async function getVaultAddress(): Promise<PublicKey> {
  let [address] = await PublicKey.findProgramAddress(
    [Buffer.from(VAULT_PREFIX)],
    program_id
  );
  return address;
}

export async function getStakeDataAddress(
  token: PublicKey
): Promise<PublicKey> {
  let [address] = await PublicKey.findProgramAddress(
    [token.toBytes()],
    program_id
  );
  return address;
}

const WHITELIST_PREFIX = "whitelist";
export async function getWhitelistDataAddress(
  candyMachine: PublicKey
): Promise<PublicKey> {
  let [address] = await PublicKey.findProgramAddress(
    [Buffer.from(WHITELIST_PREFIX), candyMachine.toBytes()],
    program_id
  );
  return address;
}

const txTimeout = 30 * 1000; // 30s

export const awaitTransactionSignatureConfirmation = async (
  txid: TransactionSignature,
  connection: Connection,
  commitment: Commitment = "finalized"
): Promise<SignatureStatus | null | void> => {
  let done = false;
  let status: SignatureStatus | null | void = {
    slot: 0,
    confirmations: 0,
    err: null,
  };
  let subId = 0;
  status = await new Promise(async (resolve, reject) => {
    setTimeout(() => {
      if (done) {
        return;
      }
      done = true;
      console.log("Rejecting for timeout...");
      reject({ timeout: true });
    }, txTimeout);

    while (!done) {
      // eslint-disable-next-line no-loop-func
      try {
        const signatureStatuses = await connection.getSignatureStatuses([txid]);
        status = signatureStatuses && signatureStatuses.value[0];
        if (!done) {
          if (!status) {
            console.log("REST null result for", txid, status);
          } else if (status.err) {
            console.log("REST error for", txid, status);
            done = true;
            reject(status.err);
          } else if (status.confirmationStatus === commitment) {
            console.log("REST confirmation for", txid, status);
            done = true;
            resolve(status);
          } else {
            console.log("REST no confirmations for", txid, status);
          }
        }
      } catch (e) {
        if (!done) {
          console.log("REST connection error: txid", txid, e);
        }
      }

      await sleep(2000);
    }
  });

  //@ts-ignore
  if (connection._signatureSubscriptions[subId]) {
    connection.removeSignatureListener(subId);
  }
  done = true;
  console.log("Returning status", status);
  return status;
};

const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const dark_key = new PublicKey("drkhMJGcWm5fRSZ1MFefExY7eq8E7pNoRKbCgdnKJ2e");
const global_key = new PublicKey("gLBD3jfPgzXMs2gKa7JSJq3xYTyym4PQcwUjmk84Any");
const associated_program = new PublicKey(
  "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
);
const escrow_account = new PublicKey(
  "L1oNSWtDQ6cyNpLjmD8HCk2GZvdp68HpsjEUqtv3UgH"
);
export const meta_program = new PublicKey(
  "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
);
const programId = fujiProgramId;

export async function createStakeTransactions(
  mints: string[],
  walletKey: PublicKey,
  mint_type: number,
  connection: Connection
) {
  let count = 0;
  let txs = [];
  let tx = new Transaction();

  for (let i = 0, l = mints.length; i < l; i++) {
    const mint_key = new PublicKey(mints[i]);
    const token_key = (await connection.getTokenLargestAccounts(mint_key))
      .value[0].address;
    const mint_escrow_key = (
      await PublicKey.findProgramAddress(
        [new Uint8Array([101, 115, 99, 114, 111, 119]), mint_key.toBuffer()],
        programId
      )
    )[0];
    const metadata_account = (
      await PublicKey.findProgramAddress(
        [
          new Uint8Array([109, 101, 116, 97, 100, 97, 116, 97]),
          meta_program.toBuffer(),
          mint_key.toBuffer(),
        ],
        meta_program
      )
    )[0];

    let account_0 = transactionKey(walletKey, true, true),
      account_1 = transactionKey(mint_key, false, true),
      account_2 = transactionKey(token_key, false, true),
      account_3 = transactionKey(mint_escrow_key, false, true),
      account_4 = transactionKey(TOKEN_PROGRAM_ID, false, false),
      account_5 = transactionKey(escrow_account, false, true),
      account_6 = transactionKey(metadata_account, false, true),
      account_7 = transactionKey(dark_key, false, false),
      account_8 = transactionKey(global_key, false, false),
      account_9 = transactionKey(SystemProgram.programId, false, false);

    tx.add(
      new TransactionInstruction({
        keys: [account_0, account_1, account_3, account_6, account_9],
        programId,
        data: Buffer.from(new Uint8Array([5, mint_type])),
      })
    );

    tx.add(
      new TransactionInstruction({
        keys: [
          account_0,
          account_1,
          account_2,
          account_3,
          account_4,
          account_5,
          account_6,
          account_7,
          account_8,
        ],
        programId,
        data: Buffer.from(new Uint8Array([2, mint_type])),
      })
    );

    if (count && count % 4 === 0) {
      txs.push(tx);
      tx = new Transaction();
    }

    count++;
  }

  if (tx.instructions.length > 0) {
    txs.push(tx);
  }

  return txs;
}

const ix_key = new PublicKey("ixTJ7LTon9X7u3dzo4sDybNb2k8bJ8UbJpbiUn1TJzT");
const fuji_mint = new PublicKey("fujiCeCeP9AFDVCv27P5JRcKLoH7wfs2C9xmDECs24m");
const backend_key = new PublicKey(
  "77LVUcuKg9DMYDjbHFtWsNHmrKogV2735pbKNaD6w5hK"
);
const tax_s_token = new PublicKey(
  "CAPJeZQRbBNhfHVyLW3hc7T2pVGfNVgMkNkmPEjEfqkh"
);

export async function createClaimTransactions(
  mints: string[],
  walletKey: PublicKey,
  mint_type: number,
  connection?: Connection
) {
  const txs = [];
  let tx = new Transaction();
  let count = 0;

  const payer_s_token = (
    await PublicKey.findProgramAddress(
      [walletKey.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), fuji_mint.toBuffer()],
      associated_program
    )
  )[0];

  let has_token = await connection.getAccountInfo(payer_s_token);

  if (has_token === null) {
    const tokenAccount = Token.createAssociatedTokenAccountInstruction(
      associated_program,
      TOKEN_PROGRAM_ID,
      fuji_mint,
      payer_s_token,
      walletKey,
      walletKey
    );
    tx.add(tokenAccount);
  }

  for (let i = 0, l = mints.length; i < l; i++) {
    const mint_key = new PublicKey(mints[i]);
    const token_key = (await connection.getTokenLargestAccounts(mint_key))
      .value[0].address;
    const mint_escrow_key = (
      await PublicKey.findProgramAddress(
        [new Uint8Array([101, 115, 99, 114, 111, 119]), mint_key.toBuffer()],
        programId
      )
    )[0];
    const meta_key = (
      await PublicKey.findProgramAddress(
        [
          new Uint8Array([109, 101, 116, 97, 100, 97, 116, 97]),
          meta_program.toBuffer(),
          mint_key.toBuffer(),
        ],
        meta_program
      )
    )[0];
    const escrow_s_token = (
      await PublicKey.findProgramAddress(
        [
          escrow_account.toBuffer(),
          TOKEN_PROGRAM_ID.toBuffer(),
          fuji_mint.toBuffer(),
        ],
        associated_program
      )
    )[0];
    const second_key = dark_key;

    let account_0 = { pubkey: walletKey, isSigner: true, isWritable: true },
      account_1 = { pubkey: tax_s_token, isSigner: false, isWritable: true },
      account_2 = {
        pubkey: TOKEN_PROGRAM_ID,
        isSigner: false,
        isWritable: false,
      },
      account_3 = { pubkey: fuji_mint, isSigner: false, isWritable: true },
      account_4 = { pubkey: payer_s_token, isSigner: false, isWritable: true },
      account_5 = { pubkey: escrow_account, isSigner: false, isWritable: true },
      account_6 = { pubkey: escrow_s_token, isSigner: false, isWritable: true },
      account_7 = { pubkey: mint_key, isSigner: false, isWritable: true },
      account_8 = {
        pubkey: mint_escrow_key,
        isSigner: false,
        isWritable: true,
      },
      account_9 = { pubkey: meta_key, isSigner: false, isWritable: true },
      account_10 = { pubkey: dark_key, isSigner: false, isWritable: true },
      account_11 = { pubkey: global_key, isSigner: false, isWritable: true };

    tx.add(
      new TransactionInstruction({
        keys: [
          account_0,
          account_1,
          account_2,
          account_3,
          account_4,
          account_5,
          account_6,
          account_7,
          account_8,
          account_9,
          account_10,
          account_11,
        ],
        programId,
        data: Buffer.from(new Uint8Array([10, mint_type])),
      })
    );

    if (count && count % 4 === 0) {
      txs.push(tx);
      tx = new Transaction();
    }

    count++;
  }

  if (tx.instructions.length > 0) {
    txs.push(tx);
  }

  return txs;
}

export async function createUnstakeTransactions(
  mints: string[],
  walletKey: PublicKey,
  mint_type: number,
  connection?: Connection
) {
  const txs = [];
  let tx = new Transaction();
  let count = 0;

  const payer_s_token = (
    await PublicKey.findProgramAddress(
      [walletKey.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), fuji_mint.toBuffer()],
      associated_program
    )
  )[0];

  let has_token = await connection.getAccountInfo(payer_s_token);

  if (has_token === null) {
    const tokenAccount = Token.createAssociatedTokenAccountInstruction(
      associated_program,
      TOKEN_PROGRAM_ID,
      fuji_mint,
      payer_s_token,
      walletKey,
      walletKey
    );
    tx.add(tokenAccount);
  }

  for (let i = 0, l = mints.length; i < l; i++) {
    const mint_key = new PublicKey(mints[i]);
    const token_key = (await connection.getTokenLargestAccounts(mint_key))
      .value[0].address;
    const mint_escrow_key = (
      await PublicKey.findProgramAddress(
        [new Uint8Array([101, 115, 99, 114, 111, 119]), mint_key.toBuffer()],
        programId
      )
    )[0];
    const meta_key = (
      await PublicKey.findProgramAddress(
        [
          new Uint8Array([109, 101, 116, 97, 100, 97, 116, 97]),
          meta_program.toBuffer(),
          mint_key.toBuffer(),
        ],
        meta_program
      )
    )[0];
    const escrow_s_token = (
      await PublicKey.findProgramAddress(
        [
          escrow_account.toBuffer(),
          TOKEN_PROGRAM_ID.toBuffer(),
          fuji_mint.toBuffer(),
        ],
        associated_program
      )
    )[0];
    const second_key = dark_key;

    let account_0 = { pubkey: walletKey, isSigner: true, isWritable: true },
      account_1 = { pubkey: mint_key, isSigner: false, isWritable: true },
      account_2 = { pubkey: token_key, isSigner: false, isWritable: true },
      account_3 = {
        pubkey: mint_escrow_key,
        isSigner: false,
        isWritable: true,
      },
      account_4 = {
        pubkey: TOKEN_PROGRAM_ID,
        isSigner: false,
        isWritable: false,
      },
      account_5 = { pubkey: escrow_account, isSigner: false, isWritable: true },
      account_6 = { pubkey: fuji_mint, isSigner: false, isWritable: true },
      account_7 = { pubkey: payer_s_token, isSigner: false, isWritable: true },
      account_8 = { pubkey: escrow_s_token, isSigner: false, isWritable: true },
      account_9 = { pubkey: tax_s_token, isSigner: false, isWritable: true },
      account_10 = { pubkey: meta_key, isSigner: false, isWritable: true },
      account_11 = { pubkey: backend_key, isSigner: false, isWritable: true },
      account_12 = { pubkey: global_key, isSigner: false, isWritable: false },
      account_13 = { pubkey: second_key, isSigner: false, isWritable: false };

    tx.add(
      new TransactionInstruction({
        keys: [
          account_0,
          account_1,
          account_2,
          account_3,
          account_4,
          account_5,
          account_6,
          account_7,
          account_8,
          account_9,
          account_10,
          account_11,
          account_12,
          account_13,
        ],
        programId,
        data: Buffer.from(new Uint8Array([3, mint_type])),
      })
    );

    if (count && count % 3 === 0) {
      txs.push(tx);
      tx = new Transaction();
    }

    count++;
  }

  if (tx.instructions.length > 0) {
    txs.push(tx);
  }

  return txs;
}

export interface StakedData {
  is_cub: boolean;
  is_dark: boolean;
  is_newborn: boolean;
  is_lion: boolean;
  local_claim: bigint;
  mint: string;
  time_last_fed: number;
  time_staked: number;
  feed_count: number;
  uri: string;
}

export async function testGPA(
  connection: Connection,
  filterCmpBytes: string
): Promise<StakedData[]> {
  const gpa = await connection.getProgramAccounts(fujiProgramId, {
    filters: [{ memcmp: { bytes: filterCmpBytes, offset: 0 } }],
  });

  return gpa.map((e) => {
    let data = e.account.data;

    let mint = new PublicKey(data.slice(32, 64)),
      feed_count = data[263],
      is_newborn = data[272] === 0,
      is_cub = data[272] === 2,
      is_lion = data[272] === 3,
      is_dark = data[273] === 1,
      //time_staked = 0,
      time_staked =
        data[264] + (data[265] << 8) + (data[266] << 16) + (data[267] << 24),
      uri = new TextDecoder().decode(data.slice(64, 264).filter((e) => e > 0)),
      time_last_fed =
        data[268] + (data[269] << 8) + (data[270] << 16) + (data[271] << 24),
      local_claim = parseUint64Le(new Uint8Array(data.slice(274, 274 + 8)));

    return {
      mint: mint.toBase58(),
      is_newborn,
      is_cub,
      is_lion,
      is_dark,
      time_staked,
      uri,
      time_last_fed,
      local_claim,
      feed_count,
    };
  });
}

const tax_key = new PublicKey("7XZtK6dViSqGQaMQ9STkPx4NDnVZqDtm1B5wyGZRhYf2");
const escrow_s_token = new PublicKey(
  "2Wze6zEFiKTTnCb25nSzCvMgfz4BZUFe9qpWKwmy695L"
);
export async function createWithdrawRewardsInstruction(
  stakedData: StakedData,
  connection: Connection,
  walletKey: PublicKey
) {
  const payer_s_token = (
    await PublicKey.findProgramAddress(
      [walletKey.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), fuji_mint.toBuffer()],
      associated_program
    )
  )[0];

  const account_0 = {
    pubkey: walletKey,
    isSigner: true,
    isWritable: true,
  };
  const account_1 = { pubkey: tax_key, isSigner: false, isWritable: true };
  const account_2 = {
    pubkey: TOKEN_PROGRAM_ID,
    isSigner: false,
    isWritable: false,
  };
  const account_3 = { pubkey: fuji_mint, isSigner: false, isWritable: true };
  const account_4 = {
    pubkey: payer_s_token,
    isSigner: false,
    isWritable: true,
  };
  const account_5 = {
    pubkey: escrow_account,
    isSigner: false,
    isWritable: true,
  };
  const account_6 = {
    pubkey: escrow_s_token,
    isSigner: false,
    isWritable: true,
  };

  const txs = [];
  let tx = new Transaction();
  let count = 0;

  const mints = [stakedData.mint];

  const mint_key = new PublicKey(mints[0]);
  const meta_key = (
    await PublicKey.findProgramAddress(
      [
        new Uint8Array([109, 101, 116, 97, 100, 97, 116, 97]),
        meta_program.toBuffer(),
        mint_key.toBuffer(),
      ],
      meta_program
    )
  )[0];
  const mint_escrow_key = (
    await PublicKey.findProgramAddress(
      [new Uint8Array([101, 115, 99, 114, 111, 119]), mint_key.toBuffer()],
      fujiProgramId
    )
  )[0];

  const account_7 = { pubkey: mint_key, isSigner: false, isWritable: true };
  const account_8 = {
    pubkey: mint_escrow_key,
    isSigner: false,
    isWritable: true,
  };
  const account_9 = { pubkey: meta_key, isSigner: false, isWritable: true };

  tx.add(
    new TransactionInstruction({
      keys: [
        account_0,
        account_1,
        account_2,
        account_3,
        account_4,
        account_5,
        account_6,
        account_7,
        account_8,
        account_9,
      ],
      programId,
      data: Buffer.from(new Uint8Array([10, stakedData.is_lion ? 0 : 1])),
    })
  );

  if (count && count % 4 === 0) {
    txs.push(tx);
    tx = new Transaction();
  }

  count++;

  if (tx.instructions.length > 0) {
    txs.push(tx);
  }

  return txs;
}

export async function asyncTxs(
  raw_txs: Transaction[],
  connection: Connection,
  wallet: any,
  signers: any[] = []
) {
  const rbh = (await connection.getRecentBlockhash()).blockhash;
  for (let i = 0, l = raw_txs.length; i < l; i++) {
    const tx = raw_txs[i];
    tx.setSigners(wallet.publicKey);

    tx.recentBlockhash = rbh;
    tx.feePayer = wallet.publicKey;

    for (let i = 0, l = signers.length; i < l; i++) {
      tx.sign(signers[i]);
    }
  }
  const signed_txs = await wallet.signAllTransactions(raw_txs);

  const promises = signed_txs.map((e) => {
    return sendAndConfirmRawTransaction(connection, e.serialize(), {
      commitment: "finalized",
      skipPreflight: true//IS_DEVNET,
    });
  });

  return Promise.all(promises).catch((e) => {
    const logs = e?.logs;
    let error = "Unknown error occurred.";

    if (logs) {
      error = logs[logs.length - 3].split(" ").splice(2).join(" ");
    }

    throw error;
  });
}

export async function countStakedNfts(connection: Connection): Promise<{
  total: number;
  lions: number;
  lionCubs: number;
  darkCubs: number;
}> {
  const gpa = await connection.getProgramAccounts(programId, {
    filters: [{ dataSize: 282 }],
  });

  const typeCounts = gpa.reduce(
    (all, e) => {
      let data = e.account.data;

      const is_cub = data[272] === 2;
      const is_lion = data[272] === 3;
      const is_dark = data[273] === 1;

      if (is_lion) {
        all.lions++;
      } else if (is_cub && !is_dark) {
        all.lionCubs++;
      } else if (is_cub && is_dark) {
        all.darkCubs++;
      }

      return all;
    },
    {
      lions: 0,
      lionCubs: 0,
      darkCubs: 0,
    }
  );

  return {
    total: typeCounts.lions + typeCounts.lionCubs + typeCounts.darkCubs,
    ...typeCounts,
  };
}

export async function mintNewbornTransaction(
  gen0: string,
  payer: PublicKey,
  connection: Connection
): Promise<[Transaction, any[]]> {
  const minter_program = fujiProgramId;
  const mint_kp = Keypair.generate();
  const token_key = (
    await PublicKey.findProgramAddress(
      [
        payer.toBuffer(),
        TOKEN_PROGRAM_ID.toBuffer(),
        mint_kp.publicKey.toBuffer(),
      ],
      associated_program
    )
  )[0];
  const meta_key = (
    await PublicKey.findProgramAddress(
      [
        new Uint8Array([109, 101, 116, 97, 100, 97, 116, 97]),
        meta_program.toBuffer(),
        mint_kp.publicKey.toBuffer(),
      ],
      meta_program
    )
  )[0];
  const edition_key = (
    await PublicKey.findProgramAddress(
      [
        new Uint8Array([109, 101, 116, 97, 100, 97, 116, 97]),
        meta_program.toBuffer(),
        mint_kp.publicKey.toBuffer(),
        new Uint8Array([101, 100, 105, 116, 105, 111, 110]),
      ],
      meta_program
    )
  )[0];
  const auth_key = (
    await PublicKey.findProgramAddress(
      [
        new Uint8Array([255, 255, 255, 255, 255, 255, 255, 95]),
        new Uint8Array([255, 255, 255, 255, 255, 255, 24]),
        minter_program.toBuffer(),
      ],
      minter_program
    )
  )[0];
  const fuji_token = (
    await PublicKey.findProgramAddress(
      [payer.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), fuji_mint.toBuffer()],
      associated_program
    )
  )[0];
  const rent_key = SYSVAR_RENT_PUBKEY;
  const sys_key = SystemProgram.programId;
  const gen0_key = new PublicKey(gen0);
  const gen0_meta_key = (
    await PublicKey.findProgramAddress(
      [
        new Uint8Array([109, 101, 116, 97, 100, 97, 116, 97]),
        meta_program.toBuffer(),
        gen0_key.toBuffer(),
      ],
      meta_program
    )
  )[0];
  const birth_key = (
    await PublicKey.findProgramAddress(
      [new Uint8Array([98, 105, 114, 116, 104]), gen0_key.toBuffer()],
      programId
    )
  )[0];

  // accounts
  let account_0 = { pubkey: ix_key, isSigner: false, isWritable: true },
    account_1 = {
      pubkey: payer,
      isSigner: true,
      isWritable: true,
    },
    account_2 = { pubkey: token_key, isSigner: false, isWritable: true },
    account_3 = {
      pubkey: mint_kp.publicKey,
      isSigner: false,
      isWritable: true,
    },
    account_4 = { pubkey: meta_key, isSigner: false, isWritable: true },
    account_5 = {
      pubkey: meta_program,
      isSigner: false,
      isWritable: false,
    },
    account_6 = { pubkey: rent_key, isSigner: false, isWritable: false },
    account_7 = { pubkey: auth_key, isSigner: false, isWritable: true },
    account_8 = {
      pubkey: TOKEN_PROGRAM_ID,
      isSigner: false,
      isWritable: false,
    },
    account_9 = { pubkey: fuji_mint, isSigner: false, isWritable: true },
    account_10 = { pubkey: fuji_token, isSigner: false, isWritable: true },
    account_11 = { pubkey: sys_key, isSigner: false, isWritable: false },
    account_12 = { pubkey: edition_key, isSigner: false, isWritable: true },
    account_13 = { pubkey: dark_key, isSigner: false, isWritable: false },
    account_14 = { pubkey: gen0_key, isSigner: false, isWritable: false },
    account_15 = { pubkey: gen0_meta_key, isSigner: false, isWritable: false },
    account_16 = { pubkey: birth_key, isSigner: false, isWritable: true };

  let mintRent = await connection.getMinimumBalanceForRentExemption(
      MintLayout.span
    ),
    tokenRent = await connection.getMinimumBalanceForRentExemption(
      AccountLayout.span
    );

  let mintAccount = SystemProgram.createAccount({
      fromPubkey: payer,
      newAccountPubkey: mint_kp.publicKey,
      lamports: mintRent,
      space: MintLayout.span,
      programId: TOKEN_PROGRAM_ID,
    }),
    tokenAccount = Token.createAssociatedTokenAccountInstruction(
      associated_program,
      TOKEN_PROGRAM_ID,
      mint_kp.publicKey,
      token_key,
      payer,
      payer
    ),
    create_token = Token.createInitMintInstruction(
      TOKEN_PROGRAM_ID,
      mint_kp.publicKey,
      0,
      payer,
      null
    ),
    mint_into_token_account = Token.createMintToInstruction(
      TOKEN_PROGRAM_ID,
      mint_kp.publicKey,
      token_key,
      payer,
      [],
      1
    ),
    birth = new TransactionInstruction({
      keys: [account_1, account_14, account_15, account_16, account_11],
      programId,
      data: Buffer.from(new Uint8Array([13])),
    }),
    instruction = new TransactionInstruction({
      keys: [
        account_0,
        account_1,
        account_2,
        account_3,
        account_4,
        account_5,
        account_6,
        account_7,
        account_8,
        account_9,
        account_10,
        account_11,
        account_12,
        account_13,
        account_14,
        account_15,
        account_16,
      ],
      programId,
      data: Buffer.from(new Uint8Array([1])),
    });

  let transaction = new Transaction().add(
    mintAccount,
    create_token,
    tokenAccount,
    mint_into_token_account,
    birth,
    instruction
  );

  return [transaction, [mint_kp]];
}

export const getTokenFunds = async (
  connection: Connection,
  walletKey: PublicKey
): Promise<undefined | TokenAmount> => {
  const payer_s_token = (
    await PublicKey.findProgramAddress(
      [walletKey.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), fuji_mint.toBuffer()],
      associated_program
    )
  )[0];

  let has_token = await connection.getAccountInfo(payer_s_token);

  if (!has_token) return;

  return (await connection.getTokenAccountBalance(payer_s_token)).value;
};

const mintedNewbornCountKey = new PublicKey(
  "ixTJ7LTon9X7u3dzo4sDybNb2k8bJ8UbJpbiUn1TJzT"
);
export const getMintedNewbornCount = async (
  connection: Connection
): Promise<number> => {
  let info = await connection.getAccountInfo(mintedNewbornCountKey);
  return info.data.readUInt16LE(0);
};

const BRED_NFT_URL =
  "https://arweave.net/hqjwnMXaK2uFst4HnoAo0MQIWAFBUa_CaWCEUIM6fXA";
export const getNewbornMeta = async () => {
  return loadMetaUri(BRED_NFT_URL);
};

export async function fastFeedNewbornTransaction(
  gen1_4: string,
  payer: PublicKey,
  connection: Connection
): Promise<[Transaction, any[]]> {
  const fuji_token = (
    await PublicKey.findProgramAddress(
      [payer.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), fuji_mint.toBuffer()],
      associated_program
    )
  )[0];
  const mint_key = new PublicKey(gen1_4);
  const mint_escrow_key = (
    await PublicKey.findProgramAddress(
      [new Uint8Array([101, 115, 99, 114, 111, 119]), mint_key.toBuffer()],
      programId
    )
  )[0];

  // accounts
  let account_0 = { pubkey: payer, isSigner: true, isWritable: true },
    account_1 = {
      pubkey: TOKEN_PROGRAM_ID,
      isSigner: false,
      isWritable: false,
    },
    account_2 = {
      pubkey: mint_key,
      isSigner: false,
      isWritable: false,
    },
    account_3 = { pubkey: fuji_mint, isSigner: false, isWritable: true },
    account_4 = { pubkey: fuji_token, isSigner: false, isWritable: true },
    account_5 = {
      pubkey: mint_escrow_key,
      isSigner: false,
      isWritable: true,
    },
    account_6 = { pubkey: backend_key, isSigner: true, isWritable: true };

  let instruction = new TransactionInstruction({
    keys: [
      account_0,
      account_1,
      account_2,
      account_3,
      account_4,
      account_5,
      account_6,
    ],
    programId,
    data: Buffer.from(new Uint8Array([8])),
  });

  let transaction = new Transaction().add(instruction);

  return [transaction, []];
}

export async function feedNewbornTransaction(
  gen1_4: string,
  payer: PublicKey,
  connection: Connection
): Promise<[Transaction, any[]]> {
  const fuji_token = (
    await PublicKey.findProgramAddress(
      [payer.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), fuji_mint.toBuffer()],
      associated_program
    )
  )[0];
  const mint_key = new PublicKey(gen1_4);
  const mint_escrow_key = (
    await PublicKey.findProgramAddress(
      [new Uint8Array([101, 115, 99, 114, 111, 119]), mint_key.toBuffer()],
      programId
    )
  )[0];

  // accounts
  let account_0 = { pubkey: payer, isSigner: true, isWritable: true },
    account_1 = {
      pubkey: TOKEN_PROGRAM_ID,
      isSigner: false,
      isWritable: false,
    },
    account_2 = {
      pubkey: mint_key,
      isSigner: false,
      isWritable: false,
    },
    account_3 = { pubkey: fuji_mint, isSigner: false, isWritable: true },
    account_4 = { pubkey: fuji_token, isSigner: false, isWritable: true },
    account_5 = {
      pubkey: mint_escrow_key,
      isSigner: false,
      isWritable: true,
    };

  let instruction = new TransactionInstruction({
    keys: [account_0, account_1, account_2, account_3, account_4, account_5],
    programId,
    data: Buffer.from(new Uint8Array([7])),
  });

  let transaction = new Transaction().add(instruction);

  return [transaction, []];
}

export async function upgradeNewbornTransaction(
  gen1_4: string,
  payer: PublicKey,
  connection: Connection
): Promise<[Transaction, any[]]> {
  const mint_key = new PublicKey(gen1_4);
  const mint_escrow_key = (
    await PublicKey.findProgramAddress(
      [new Uint8Array([101, 115, 99, 114, 111, 119]), mint_key.toBuffer()],
      programId
    )
  )[0];
  const meta_key = (
    await PublicKey.findProgramAddress(
      [
        new Uint8Array([109, 101, 116, 97, 100, 97, 116, 97]),
        meta_program.toBuffer(),
        mint_key.toBuffer(),
      ],
      meta_program
    )
  )[0];
  const token_key = (await connection.getTokenLargestAccounts(mint_key))
    .value[0].address;

  // accounts
  let account_0 = { pubkey: payer, isSigner: false, isWritable: true },
    account_1 = {
      pubkey: mint_key,
      isSigner: false,
      isWritable: false,
    },
    account_2 = {
      pubkey: token_key,
      isSigner: false,
      isWritable: true,
    },
    account_3 = {
      pubkey: mint_escrow_key,
      isSigner: false,
      isWritable: true,
    },
    account_4 = {
      pubkey: TOKEN_PROGRAM_ID,
      isSigner: false,
      isWritable: false,
    },
    account_5 = { pubkey: escrow_account, isSigner: false, isWritable: true },
    account_6 = { pubkey: meta_program, isSigner: false, isWritable: false },
    account_7 = { pubkey: meta_key, isSigner: false, isWritable: true },
    account_8 = { pubkey: dark_key, isSigner: false, isWritable: true };

  let instruction = new TransactionInstruction({
    keys: [
      account_0,
      account_1,
      account_2,
      account_3,
      account_4,
      account_5,
      account_6,
      account_7,
      account_8,
    ],
    programId,
    data: Buffer.from(new Uint8Array([4])),
  });

  let transaction = new Transaction().add(instruction);

  return [transaction, []];
}

export enum StakeMintType {
  gen0 = 0,
  gen1_4 = 1,
}

const apiClaimUrl = process.env.REACT_APP_FUJI_API_CLAIM!;
export const claimOnWorker = async (
  connection: Connection,
  given_tx: Transaction | Transaction[],
  wallet: WalletContextState
) => {
  let txs: Transaction[] = [];
  if (Array.isArray(given_tx)) {
    txs = given_tx;
  } else {
    txs.push(given_tx);
  }

  let rbh = (await connection.getRecentBlockhash()).blockhash;
  for (let i = 0, l = txs.length; i < l; i++) {
    const tx = txs[i];
    tx.setSigners(wallet.publicKey, backend_key);

    tx.recentBlockhash = rbh;
    tx.feePayer = wallet.publicKey;
  }
  const signed_txs = await wallet.signAllTransactions(txs);

  const promises = signed_txs.map((e) => {
    const payload = e.serialize({
      requireAllSignatures: false,
      verifySignatures: false,
    });

    return fetch(apiClaimUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-binary",
      },
      body: payload,
    })
      .then((r) => {
        if (!r.ok) throw "error claiming on worker";
        return r;
      })
      .catch((e: any) => {
        throw "Error with risky game.";
      })
      .then((r) => r.json());
  });

  const resu = await Promise.all(promises);

  return resu[0];
};

export async function createClaimTaxTransactions(
  mints: string[],
  walletKey: PublicKey,
  connection?: Connection
) {
  const txs = [];
  let tx = new Transaction();
  let count = 0;

  const payer_s_token = (
    await PublicKey.findProgramAddress(
      [walletKey.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), fuji_mint.toBuffer()],
      associated_program
    )
  )[0];

  let has_token = await connection.getAccountInfo(payer_s_token);

  if (has_token === null) {
    const tokenAccount = Token.createAssociatedTokenAccountInstruction(
      associated_program,
      TOKEN_PROGRAM_ID,
      fuji_mint,
      payer_s_token,
      walletKey,
      walletKey
    );
    tx.add(tokenAccount);
  }

  for (let i = 0, l = mints.length; i < l; i++) {
    const mint_key = new PublicKey(mints[i]);
    const token_key = (await connection.getTokenLargestAccounts(mint_key))
      .value[0].address;
    const mint_escrow_key = (
      await PublicKey.findProgramAddress(
        [new Uint8Array([101, 115, 99, 114, 111, 119]), mint_key.toBuffer()],
        programId
      )
    )[0];
    const meta_key = (
      await PublicKey.findProgramAddress(
        [
          new Uint8Array([109, 101, 116, 97, 100, 97, 116, 97]),
          meta_program.toBuffer(),
          mint_key.toBuffer(),
        ],
        meta_program
      )
    )[0];
    const escrow_s_token = (
      await PublicKey.findProgramAddress(
        [
          escrow_account.toBuffer(),
          TOKEN_PROGRAM_ID.toBuffer(),
          fuji_mint.toBuffer(),
        ],
        associated_program
      )
    )[0];

    let account_0 = { pubkey: walletKey, isSigner: true, isWritable: true },
      account_1 = { pubkey: mint_key, isSigner: false, isWritable: true },
      account_2 = {
        pubkey: mint_escrow_key,
        isSigner: false,
        isWritable: true,
      },
      account_3 = {
        pubkey: TOKEN_PROGRAM_ID,
        isSigner: false,
        isWritable: false,
      },
      account_4 = { pubkey: escrow_account, isSigner: false, isWritable: true },
      account_5 = { pubkey: fuji_mint, isSigner: false, isWritable: true },
      account_6 = { pubkey: payer_s_token, isSigner: false, isWritable: true },
      account_7 = { pubkey: escrow_s_token, isSigner: false, isWritable: true },
      account_8 = { pubkey: meta_key, isSigner: false, isWritable: false },
      account_9 = { pubkey: global_key, isSigner: false, isWritable: false },
      account_10 = { pubkey: dark_key, isSigner: false, isWritable: false };

    tx.add(
      new TransactionInstruction({
        keys: [
          account_0,
          account_1,
          account_2,
          account_3,
          account_4,
          account_5,
          account_6,
          account_7,
          account_8,
          account_9,
          account_10,
        ],
        programId,
        data: Buffer.from(new Uint8Array([12])),
      })
    );

    if (count && count % 4 === 0) {
      txs.push(tx);
      tx = new Transaction();
    }

    count++;
  }

  if (tx.instructions.length > 0) {
    txs.push(tx);
  }

  return txs;
}
