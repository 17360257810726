import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";

export const DialogCubStolen = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: Function;
}) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={() => onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Your cub was stolen :(
          <IconButton
            aria-label="close"
            onClick={() => onClose()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>Oh no!</div>
            <div>Your cub was stolen by an Evil Cub :(</div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
