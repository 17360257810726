import {
  Metadata,
  MetadataData,
} from "@metaplex-foundation/mpl-token-metadata";
import { Connection, PublicKey } from "@solana/web3.js";
import { IS_DEVNET } from "../config";
import { retry } from "./promise";
import { meta_program, parseUint64Le } from "./staking";

export interface ArweaveAttribute {
  trait_type: string;
  value: string;
}
export interface ArweaveMetadata {
  name: string;
  description: string;
  image: string;
  attributes: ArweaveAttribute[];
}

export enum FujiType {
  Gen0,
  Newborn,
  Cub,
  DarkCub,
}

export enum FujiSymbol {
  newborn = "NEWBORN",
}

const GEN0_AUTHORITY = new Set([
  "FujiCoMtbD41YSTkNhKSrQ8n3qjJA3LpPp9GSnU6gLzH",
  "Ced2v4DMAGQDPJAbiqy1m4yTF4p3wmzm38AjwrUVzSoP",
  "GoD1TBL1P4VcenkRAHgMx6zQTNJu21UhJ5zHfFZA6T7E",
]);

export const getFujiType = (meta: MetadataData): FujiType => {
  if (GEN0_AUTHORITY.has(meta.updateAuthority)) return FujiType.Gen0;

  const {
    data: { name },
  } = meta;

  if (meta.data.symbol === "NEWBORN") return FujiType.Newborn;
  else if (name.startsWith("Fuji Cub")) return FujiType.Cub;
  else if (name.startsWith("Dark")) return FujiType.DarkCub;

  return FujiType.Gen0;
};

const fujiCreatorAddresses = IS_DEVNET
  ? new Set([
      "L1oNSWtDQ6cyNpLjmD8HCk2GZvdp68HpsjEUqtv3UgH",
      "7V5HgodrUb1jebRpFDsxTnYMKvEbMvbpTLn9kCinHPdd",
    ])
  : new Set([
      "L1oNSWtDQ6cyNpLjmD8HCk2GZvdp68HpsjEUqtv3UgH",
      "Ced2v4DMAGQDPJAbiqy1m4yTF4p3wmzm38AjwrUVzSoP",
      "GoD1TBL1P4VcenkRAHgMx6zQTNJu21UhJ5zHfFZA6T7E",
    ]);

export const loadMetaUri = async (uri: string) => {
  return await fetch(uri).then((r) => r.json());
};

export const getMintedNftMeta = async (
  connection: Connection,
  walletKey: PublicKey
): Promise<[MetadataData, ArweaveMetadata, FujiType][]> => {
  const metas: any[] = [];

  const ownerMeta = await Metadata.findDataByOwner(connection, walletKey);

  for (let meta of ownerMeta) {
    if (
      meta &&
      meta.data &&
      Array.isArray(meta.data.creators) &&
      meta.data.creators.some(
        (creator) =>
          creator.verified && fujiCreatorAddresses.has(creator.address)
      ) &&
      typeof meta.data.uri === "string"
    ) {
      metas.push(
        loadMetaUri(meta.data.uri)
          .then((response) => [meta, response, getFujiType(meta)])
          .catch(() => null)
      );
    }
  }

  return Promise.all(metas).then((nfts) => nfts.filter(Boolean));
};

export const loadNftMeta = async (
  connection: Connection,
  mint: string
): Promise<[MetadataData, ArweaveMetadata]> => {
  const meta_key = (
    await PublicKey.findProgramAddress(
      [
        new Uint8Array([109, 101, 116, 97, 100, 97, 116, 97]),
        meta_program.toBuffer(),
        new PublicKey(mint).toBuffer(),
      ],
      meta_program
    )
  )[0];

  const nft = await connection.getAccountInfo(new PublicKey(meta_key));
  const metadata = MetadataData.deserialize(nft.data);
  return [metadata, await loadMetaUri(metadata.data.uri)];
};

const global_key = new PublicKey("gLBD3jfPgzXMs2gKa7JSJq3xYTyym4PQcwUjmk84Any");
export const getGlobalu64 = async (connection: Connection): Promise<bigint> => {
  let global_info = await connection.getAccountInfo(global_key);
  return parseUint64Le(global_info.data);
};
