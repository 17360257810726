import styled from "styled-components";
import { FC, useCallback, useEffect, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import {
  Connection,
  PublicKey,
  TokenAmount,
  Transaction,
} from "@solana/web3.js";

import {
  ArweaveMetadata,
  FujiType,
  getFujiType,
  getMintedNftMeta,
  loadMetaUri,
  loadNftMeta,
} from "../../utils/nft";

import {
  asyncTxs,
  claimOnWorker,
  createStakeTransactions,
  fastFeedNewbornTransaction,
  feedNewbornTransaction,
  getTokenFunds,
  StakedData,
  StakeMintType,
  testGPA,
  upgradeNewbornTransaction,
} from "../../utils/staking";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { errorCodes } from "eth-rpc-errors/dist/error-constants";
import { ConnectButton } from "../../components/ConnectButton";
import {
  integerNumberFormat,
  IS_DEVNET,
  numberFormat,
  TOKEN_NAME,
} from "../../config";
import ImageToken from "../../layout/images/fuji-token.png";
import { formatRemaining } from "../../utils/time";
import { WalletHeader } from "../../components/WalletHeader";
import { mdiFoodDrumstickOutline, mdiSkullOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Close } from "@mui/icons-material";

const Loading = styled.div`
  color: white;
  min-height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleSection = ({
  walletAddress,
  tokenFunds,
}: {
  tokenFunds: TokenAmount | undefined;
  walletAddress?: PublicKey;
}) => {
  return (
    <WalletHeader walletAddress={walletAddress} title="Feeding">
      <div className="font-semibold flex items-center">
        {tokenFunds && (
          <>
            {TOKEN_NAME}

            <img className="h-5 mx-2" src={ImageToken} alt="" />

            {integerNumberFormat.format(tokenFunds?.uiAmount ?? 0)}
          </>
        )}
        &nbsp;
      </div>
    </WalletHeader>
  );
};

const NftCardDiv = styled.div`
  border: 1px solid #5c4cb6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.selected {
    background-color: #5c4cb6;
  }
`;

const ONE_DAY_S = 60 * 60 * 24;

const NftCard: FC<{
  isUnstaked: boolean;
  metadata: StakedData;
  arweaveMetadata: ArweaveMetadata;
  mint: string;
  onFeed: (mint: string) => void;
  onEvolve: (mint: string) => void;
  onPlayRiskyGame: (mint: string, timesFed: number) => void;
  onStake: (mint: string) => void;
  tokenFunds: TokenAmount;
}> = ({
  isUnstaked,
  onStake,
  arweaveMetadata,
  metadata,
  mint,
  onFeed,
  onEvolve,
  onPlayRiskyGame,
  tokenFunds,
}) => {
  const [isDead, setIsDead] = useState(false);
  const [now, setNow] = useState(Math.floor(Date.now() / 1000));

  const fedCount: number = metadata.feed_count;

  useEffect(() => {
    setTimeout(() => {
      const relevantTime =
        fedCount === 0
          ? metadata.time_staked + ONE_DAY_S
          : metadata.time_last_fed + ONE_DAY_S * 2;

      if (!isUnstaked) {
        setIsDead(relevantTime < now);
      }

      setNow(Math.floor(Date.now() / 1000));
    }, 1000);
  }, [now]);

  if (isDead) return <></>;

  const hasFeedingFunds = tokenFunds.uiAmount >= 10;
  const needsToWaitWithFeeding =
    fedCount > 0 && metadata.time_last_fed + ONE_DAY_S > now;
  const timeOfDeath =
    fedCount === 0
      ? metadata.time_staked + ONE_DAY_S
      : metadata.time_last_fed + ONE_DAY_S * 2;

  const canFeed = hasFeedingFunds && !needsToWaitWithFeeding;
  const canEvolve = fedCount >= 5;
  const showDeathCountdown =
    fedCount === 0 || metadata.time_last_fed + ONE_DAY_S < now;

  return (
    <>
      <NftCardDiv className={"p-3 relative"} key={arweaveMetadata.name}>
        <h2 className="text-white text-1xl font-semibold mb-3 leading-4">
          {arweaveMetadata.name}
        </h2>
        {!isUnstaked && (
          <div className="mb-2">
            {!showDeathCountdown ? (
              <>&nbsp;</>
            ) : (
              <div className="flex">
                <Icon className="-ml-6 mr-2" path={mdiSkullOutline} size={1} />
                {formatRemaining(timeOfDeath * 1000)}
              </div>
            )}
          </div>
        )}
        <img className="rounded-md" src={arweaveMetadata.image} alt="" />

        {isUnstaked ? (
          <div>
            <NftCardButton
              className="mt-4 block py-2 px-4 text-center w-full rounded-md primary-bg text-white"
              onClick={() => onStake(mint)}
            >
              Stake
            </NftCardButton>
          </div>
        ) : (
          <>
            <div className="flex mt-3 mb-2">
              <Icon
                className="-ml-6 mr-2"
                path={mdiFoodDrumstickOutline}
                size={1}
              />
              Fed {fedCount} / 5 times
            </div>
            <div className="relative w-full">
              {canEvolve ? (
                <NftCardButton
                  className="mt-2 block py-2 px-4 text-center w-full rounded-md primary-bg text-white"
                  onClick={() => onEvolve(mint)}
                >
                  Evolve
                </NftCardButton>
              ) : (
                <NftCardButton
                  className="mt-2 block py-2 px-4 text-center w-full rounded-md primary-bg text-white"
                  disabled={!canFeed || !hasFeedingFunds}
                  onClick={() => canFeed && onFeed(mint)}
                >
                  {hasFeedingFunds ? (
                    <>
                      {needsToWaitWithFeeding ? (
                        <>
                          Wait{" "}
                          {formatRemaining(
                            (metadata.time_last_fed + ONE_DAY_S) * 1000
                          )}
                        </>
                      ) : (
                        <>Feed for 10 {TOKEN_NAME}</>
                      )}
                    </>
                  ) : (
                    <>You need 10 $FUJI to feed!</>
                  )}
                </NftCardButton>
              )}

              <NftCardButton
                className="mt-2 block py-2 px-4 text-center w-full rounded-md primary-bg text-white"
                onClick={() => onPlayRiskyGame(mint, fedCount)}
                disabled={fedCount >= 5}
              >
                Play Risky Game
              </NftCardButton>
            </div>
          </>
        )}
      </NftCardDiv>
    </>
  );
};

const NftCardButton = styled.button`
  background: #db4261;
  color: #fff;
  border-color: #fff;
  border-radius: 0;
  box-shadow: 2px 3px 0 #201c37;
  display: block;
  padding: 6px 16px;

  &[disabled] {
    opacity: 0.25;
    pointer-events: none;
  }
`;

const NftActionCards: FC<{
  metas: [StakedData, ArweaveMetadata][];
  emptyText: string;
  onFeed: (mint: string) => void;
  onEvolve: (mint: string) => void;
  onPlayRiskyGame: (mint: string, timesFed: number) => void;
  onStake: (mint: string) => void;
  connection: any;
  tokenFunds: TokenAmount;
  isUnstaked: boolean;
}> = ({
  metas,
  emptyText,
  onFeed,
  onEvolve,
  onPlayRiskyGame,
  connection,
  tokenFunds,
  isUnstaked,
  onStake,
}) => {
  return (
    <div>
      {metas.length > 0 ? (
        <>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
            {metas
              .sort(([, a], [, b]) => a.name.localeCompare(b.name))
              .map(([metadata, arweaveMeta]) => {
                return (
                  <NftCard
                    metadata={metadata}
                    key={metadata.mint}
                    mint={metadata.mint}
                    tokenFunds={tokenFunds}
                    arweaveMetadata={arweaveMeta}
                    onFeed={onFeed}
                    onEvolve={onEvolve}
                    onPlayRiskyGame={onPlayRiskyGame}
                    onStake={onStake}
                    isUnstaked={isUnstaked}
                  />
                );
              })}
          </div>
        </>
      ) : (
        <>
          <div className="text-gray-400 text-center py-20">{emptyText}</div>
        </>
      )}
    </div>
  );
};

enum RiskyGameResultType {
  stolen,
  win,
  lion,
  evil,
}
type EvolveResult =
  | undefined
  | { title: string; type: RiskyGameResultType.stolen }
  | { title: string; type: RiskyGameResultType.win }
  | { title: string; type: RiskyGameResultType.evil; image: string }
  | { title: string; type: RiskyGameResultType.lion; image: string };

const DialogEvolveResult = ({
  onClose,
  result,
}: {
  onClose: Function;
  result: EvolveResult;
}) => {
  if (!result) return <></>;

  let resultContent = <></>;

  switch (result.type) {
    case RiskyGameResultType.win: {
      resultContent = (
        <>
          <div>Yes!</div>
          <div>You won risky game and barely escaped the Evil Cubs!</div>
          <div>Your newborn is fully fed and can now be evolved!</div>
        </>
      );
      break;
    }
    case RiskyGameResultType.stolen: {
      resultContent = (
        <>
          <div>Oh no!</div>
          <div>Your newborn was stolen by an Evil Cub :(</div>
        </>
      );
      break;
    }
    case RiskyGameResultType.evil:
    case RiskyGameResultType.lion: {
      resultContent = (
        <div className="flex justify-center items-center flex-col">
          <img className="w-60 mb-2" src={result.image} alt="" />
          <div className="mb-2">Congratulations</div>
          <div>
            Your newborn turned into a
            {result.type === RiskyGameResultType.evil
              ? "n Evil Cub"
              : " Lion Cub"}
            .
          </div>
        </div>
      );
      break;
    }
  }

  return (
    <>
      <Dialog
        open={result !== undefined}
        onClose={() => onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {result.title}
          <IconButton
            aria-label="close"
            onClick={() => onClose()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {resultContent}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

const DialogConfirmRiskyGame = ({
  data,
  onConfirm,
  onCancel,
}: {
  data: { timesFed: number; mint: string } | undefined;
  onConfirm: (mint: string) => void;
  onCancel: Function;
}) => {
  if (!data) return <></>;

  return (
    <>
      <Dialog
        open={!!data}
        onClose={() => onCancel()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Risky Game
          <IconButton
            aria-label="close"
            onClick={() => onCancel()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="mb-3">
              By playing the <strong>risky game</strong>, you can{" "}
              <strong>
                spend {numberFormat.format(50 - data.timesFed * 10)}{" "}
                {TOKEN_NAME}
              </strong>{" "}
              to <strong>evolve your newborn</strong> into a cub{" "}
              <strong>immediately</strong>.
            </div>
            <div className="mb-3">
              However, there is a <strong>10% chance</strong> that your cub will
              be <strong>stolen by an evil cub</strong>. This means you will{" "}
              <strong>lose your cub permanently</strong>.
            </div>
            <div>Are you sure you want to proceed?</div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => onCancel()}
            autoFocus
            color="error"
            variant="contained"
          >
            No
          </Button>
          <Button
            onClick={() => onConfirm(data.mint)}
            color="success"
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const FeedSection = ({
  metas,
  stakedMetas,
  tokenFunds,
  connection,
  feed,
  evolve,
  stake,
  playRiskyGame,
}: {
  metas: [StakedData, ArweaveMetadata][];
  stakedMetas: [StakedData, ArweaveMetadata][];
  tokenFunds: TokenAmount;
  connection: any;
  feed: (mint: string) => unknown;
  evolve: (mint: string) => unknown;
  playRiskyGame: (mint: string, timesFed: number) => unknown;
  stake: (mints: string, mint_type: number) => unknown;
}) => {
  return (
    <>
      <section id="my-nfts">
        <div className="container pb-5">
          <div className="text-3xl font-semibold mb-4 sm:flex flex-row items-center">
            <h2>Unstaked</h2>
          </div>
          <div className="mb-4">
            <NftActionCards
              metas={metas.filter(([m]) => m.is_newborn)}
              emptyText={"You don't have any feedable NFTs 😟"}
              connection={connection}
              tokenFunds={tokenFunds}
              isUnstaked={true}
              onFeed={() => {}}
              onEvolve={() => {}}
              onPlayRiskyGame={() => {}}
              onStake={(mint) => {
                stake(mint, 0);
              }}
            />
          </div>

          <div className="text-3xl font-semibold pt-4 mb-4 sm:flex flex-row items-center">
            <h2>Staked</h2>
          </div>
          <div className="mb-4">
            <NftActionCards
              isUnstaked={false}
              metas={stakedMetas.filter(
                ([m]) => m.is_newborn || m.feed_count >= 5
              )}
              emptyText={"You don't have any staked NFTs 😟"}
              connection={connection}
              tokenFunds={tokenFunds}
              onStake={() => {}}
              onFeed={(mint) => {
                feed(mint);
              }}
              onEvolve={(mint) => {
                evolve(mint);
              }}
              onPlayRiskyGame={playRiskyGame}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export const Feeding = ({ connection }: { connection: Connection }) => {
  const { enqueueSnackbar } = useSnackbar();
  const wallet = useWallet();
  const [loading, setLoading] = useState<string | null>(null);
  const [confirmRiskyMint, setConfirmRiskyMint] = useState<
    { mint: string; timesFed: number } | undefined
  >(undefined);
  const [evolveResult, setEvolveResult] = useState<EvolveResult>(undefined);
  const [metas, setMetas] = useState<[StakedData, ArweaveMetadata][]>([]);
  const [stakedMetas, setStakedMetas] = useState<
    [StakedData, ArweaveMetadata][]
  >([]);
  const [tokenFunds, setTokenFunds] = useState<undefined | TokenAmount>(
    undefined
  );

  const fetchMeta = useCallback(async () => {
    if (!wallet) return;
    if (!wallet.publicKey) return;

    setLoading("Loading FUJIs");
    const metas = await getMintedNftMeta(connection, wallet.publicKey!);
    setTokenFunds(await getTokenFunds(connection, wallet.publicKey!));
    const gpa = await testGPA(connection, wallet.publicKey.toBase58());

    const stakedData = (
      await Promise.all(
        gpa.map((data) =>
          loadMetaUri(data.uri)
            .then(
              (arweaveData) =>
                [data, arweaveData] as [StakedData, ArweaveMetadata]
            )
            .catch(() => null)
        )
      )
    ).filter(Boolean);
    setStakedMetas(stakedData);

    setLoading(null);
    setMetas(
      metas.map(([meta, arweave, type]) => [
        {
          mint: meta.mint,
          is_cub: type === FujiType.Cub,
          is_dark: type === FujiType.DarkCub,
          is_newborn: type === FujiType.Newborn,
          is_lion: type === FujiType.Gen0,
          time_last_fed: -1,
          time_staked: -1,
          local_claim: BigInt(-1),
          feed_count: -1,
          uri: meta.data.uri,
        },
        arweave,
      ])
    );
  }, [wallet]);

  useEffect(() => {
    fetchMeta();
  }, [fetchMeta]);

  const sendInstructions = useCallback(
    async (transaction: Transaction) => {
      await wallet.signTransaction(transaction);

      return await wallet.sendTransaction(transaction, connection, {
        skipPreflight: IS_DEVNET,
      });
    },
    [connection, wallet?.publicKey, wallet.sendTransaction]
  );

  const handleStakeUnStakeError = (message: string, error: any) => {
    if (error?.error?.code === errorCodes.provider.userRejectedRequest) return;
    const errorMessage = error?.message ?? undefined;
    enqueueSnackbar(
      `Sorry there was an error feeding your Fuji. Please try again. ${
        errorMessage ? `Reason: ${errorMessage}` : ""
      }`,

      {
        variant: "error",
      }
    );
  };

  const feed = useCallback(
    async (mint: string) => {
      try {
        const [tx, signers] = await feedNewbornTransaction(
          mint,
          wallet.publicKey,
          connection
        );
        setLoading("Waiting for feeding confirmation");
        await asyncTxs([tx], connection, wallet, signers);
        enqueueSnackbar("Fed successfully", {
          variant: "success",
        });
        await fetchMeta();
        setLoading(null);
      } catch (error: any) {
        setLoading(null);
        handleStakeUnStakeError(
          "Sorry there was an error when feeding. Please try again.",
          error
        );
        throw error;
      }
    },
    [connection, wallet?.publicKey, sendInstructions]
  );
  const evolve = useCallback(
    async (mint: string) => {
      try {
        const [tx, signers] = await upgradeNewbornTransaction(
          mint,
          wallet.publicKey,
          connection
        );
        setLoading("Waiting for evolution confirmation");
        await asyncTxs([tx], connection, wallet, signers);
        enqueueSnackbar("Evolved successfully", {
          variant: "success",
        });

        const [meta, arweave] = await loadNftMeta(connection, mint);
        setEvolveResult({
          title: "Evolution",
          type:
            getFujiType(meta) === FujiType.DarkCub
              ? RiskyGameResultType.evil
              : RiskyGameResultType.lion,
          image: arweave.image,
        });

        await fetchMeta();
        setLoading(null);
      } catch (error: any) {
        setLoading(null);
        handleStakeUnStakeError(
          "Sorry there was an error trying to evolve. Please try again.",
          error
        );
        throw error;
      }
    },
    [connection, wallet?.publicKey, sendInstructions]
  );

  const playRiskyGame = useCallback(
    async (mint: string) => {
      try {
        const [tx] = await fastFeedNewbornTransaction(
          mint,
          wallet.publicKey,
          connection
        );
        setLoading("Waiting for risky game confirmation");

        const { result } = await claimOnWorker(connection, tx, wallet);

        enqueueSnackbar("Risky game played", {
          variant: "success",
        });

        if (result === "stolen") {
          setEvolveResult({
            title: "Risky Game",
            type: RiskyGameResultType.stolen,
          });
        } else {
          const [meta, arweave] = await loadNftMeta(connection, mint);
          setEvolveResult({
            title: "Risky Game",
            type: RiskyGameResultType.win,
          });
        }

        await fetchMeta();
        setLoading(null);
      } catch (error: any) {
        setLoading(null);
        handleStakeUnStakeError(
          "Sorry there was an error when playing the risky game. Please try again.",
          error
        );
        throw error;
      }
    },
    [connection, wallet?.publicKey, sendInstructions]
  );

  const stake = useCallback(
    async (unstaked_selection: string[], mint_type: number) => {
      try {
        const txs = await createStakeTransactions(
          unstaked_selection,
          wallet.publicKey,
          mint_type,
          connection
        );
        setLoading("Waiting for staking confirmation");
        await asyncTxs(txs, connection, wallet);
        enqueueSnackbar("Staked successfully", {
          variant: "success",
        });
        await fetchMeta();
        setLoading(null);
      } catch (error: any) {
        setLoading(null);
        handleStakeUnStakeError(
          "Sorry there was an error when staking. Please try again.",
          error
        );
      }
    },
    [connection, wallet?.publicKey, sendInstructions]
  );

  const pageContent = (
    <>
      {loading ? (
        <Loading>
          <div className="flex flex-col items-center">
            <CircularProgress color="inherit" />
            <div className="mt-4">{loading}</div>
          </div>
        </Loading>
      ) : (
        <FeedSection
          metas={metas}
          stakedMetas={stakedMetas}
          feed={(mint) => feed(mint)}
          evolve={(mint) => evolve(mint)}
          stake={(mint) => stake([mint], StakeMintType.gen1_4)}
          playRiskyGame={(mint, timesFed) =>
            setConfirmRiskyMint({ mint, timesFed })
          }
          tokenFunds={tokenFunds}
          connection={connection}
        />
      )}
    </>
  );

  return (
    <>
      <TitleSection walletAddress={wallet?.publicKey} tokenFunds={tokenFunds} />
      <>
        <DialogConfirmRiskyGame
          data={confirmRiskyMint}
          onConfirm={(mint) => {
            playRiskyGame(mint);
            setConfirmRiskyMint(undefined);
          }}
          onCancel={() => setConfirmRiskyMint(undefined)}
        />
        <DialogEvolveResult
          result={evolveResult}
          onClose={() => setEvolveResult(undefined)}
        />
      </>
      {wallet?.publicKey ? (
        <>{pageContent}</>
      ) : (
        <section>
          <div className="container">
            <div className="md:flex items-center flex-row justify-center">
              <ConnectButton>Connect</ConnectButton>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
